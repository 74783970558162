const _import = require('../config/import')
const homeRoute = [
  {
    path: '/home',
    component: _import('modules/home/home'),
    name: 'home',
    meta: { title: '首页', nav: true },
  },
  {
    path: '/news',
    component: _import('modules/home/news'),
    name: 'news',
    meta: { title: '新闻列表', nav: false ,isBreadcrumb:true},
  },
  {
    path: '/newsDetail',
    component: _import('modules/home/newsDetail'),
    name: 'newsDetail',
    meta: { title: '新闻详情', nav: false ,isBreadcrumb:true},
  },
  {
    path: '/ybdt',
    component: _import('modules/home/ybdt'),
    name: 'ybdt',
    meta: { title: '院部动态', nav: false ,isBreadcrumb:true},
  },
  {
    path: '/ybdtDetail',
    component: _import('modules/home/ybdtDetail'),
    name: 'ybdtDetail',
    meta: { title: '院部动态', nav: false ,isBreadcrumb:true},
  },
  {
    path: '/szyw',
    component: _import('modules/home/szyw'),
    name: 'szyw',
    meta: { title: '时政要闻', nav: false ,isBreadcrumb:true},
  },
  {
    path: '/szywDetail',
    component: _import('modules/home/szywDetail'),
    name: 'szywDetail',
    meta: { title: '时政要闻', nav: false ,isBreadcrumb:true},
  },
]
export default homeRoute