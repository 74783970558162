<template>
  <div class="box">
    <div class="box-search flex-start">
      <el-input
          style="margin-right:10px"
          @keyup.enter.native="getSearch"
          v-model="keyword"
          placeholder="搜索关键字"
      ></el-input>
      <el-button @click="getSearch" type="primary">搜索</el-button>
    </div>
    <div class="box-search flex-start">
      历史搜索：
      <ul class="searchhistoey flex-start">
        <li v-for="a,i in history" :key="i" @click="getSearchHistory(a)">{{ a }}</li>
      </ul>
    </div>
    <div class="box-list">
      <ul class="list">

        <li
            v-for="item in sign"
            @click="goPath(item,'bkzxDetail')"
            :key="item.id"
            class="flex-start"
        >
          <img :src="item.default_img" alt/>
          <div class="content">
            <p class="title">{{ item.title }}</p>
            <p class="date">{{ item.created_at.substring(0, 10) }}</p>
          </div>
        </li>
        <li v-for="a in list" :key="i" @click="goRoute(a)" class="flex-between">
          <div class="itemInfo">
            <p class="title">{{ a.title }}</p>
            <p v-if="a.des" class="des">{{ a.des }}</p>
            <p class="date">{{ a.date }}</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 分页 -->
    <div v-if="total>0" class="pagination flex-center">
      <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getPageNum"
          :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
/* 
分类,
,xxxw 学校新闻，ybdt院部动态，szyw时政要闻，zcwx政策文献,xyfm校园风貌,party先锋旗帜,active党建活动,dflz党风廉政, znjs智能技术产业系，
szxx数字信息产业系，jtqc交通与汽车产业系，hjny环境与能源产业系，mzwh民族文化产业系，lysm旅游与商贸产业系，jcwh基础文化部，jxyj教学研究，
zyjs专业建设，jxcg教学成果，jsfc教师风采 xywh校园文化，szjy思政教育，gfjy国防教育，xljk心理健康教育，xszz学生资助，twgz团委工作，
bkzx招生资讯,zsjh招生计划,xssx学生实习,jycy就业信息,pxxm社会化短期培训,pxdt技能评价,aid援助,factors校企合作
*/
import {Local} from "@/utils/storage";

export default {
  name: 'search',
  data() {
    return {
      keyword: "",
      list: [],
      next: 0,
      page: 0,
      size: 10,
      total: 0,
      types: {
        xxxw: "newsDetail",
        ybdt: "ybdtDetail",
        szyw: "szywDetail",
        zcwx: "zcwxDetail",
        xyfm: "xyfmDetail",
        party: "xfqzDetail",
        active: "djhdDetail",
        dflz: "dflzDetail",
        znjs: "znjsDetail",
        szxx: "szxxDetail",
        jtqc: "jtyqcDetail",
        hjny: "hjynyDetail",
        mzwh: "mzwhDetail",
        lysm: "lyysmDetail",
        cultural: "jcwhbDetail",
        sshd: "sshdDetail",
        jxyj: "jxyjDetail",
        zyjs: "zyjsDetail",
        jxcg: "jxcgDetail",
        jsfc: "jsfcDetail",
        xywh: "xywhDetail",
        szjy: "szjyDetail",
        gfjy: "gfjyDetail",
        xljk: "xljkjyDetail",
        xszz: "xszzDetail",
        twgz: "twgzDetail",
        bkzx: "bkzxDetail",
        zsjh: "zsjhDetail",
        xssx: "xssxDetail",
        jycy: "jycyDetail",
        pxxm: "pxxmDetail",
        pxdt: "pxdtDetail",
        aid: "sygzDetail",
        factors: "xqhzDetail",
      },
      history: Local.get("history_arr") || [],
    };
  },
  methods: {
    goRoute(a) {
      if (a.url) {
        window.open(a.url);
      } else {
        var type = a.type;
        this.$router.push({
          name: this.types[type],
          query: {id: a.id},
        });
      }
    },

    getPageNum(e) {
      this.page = e - 1;
      this.getSearch();
    },
    setHistory(keyword) {
      let historyList = Local.get("history_arr") || [];
      if (historyList.some(o => o == keyword)) {
        return false
      }
      if (historyList && historyList.length < 5) {
        historyList.unshift(keyword);
        Local.set("history_arr", historyList);
        this.history = historyList;
      }
      if (historyList && historyList.length >= 5) {
        historyList.pop();
        historyList.unshift(keyword);
        Local.set("history_arr", historyList);
        this.history = historyList;
      }
    },
    getSearch() {
      let {keyword, page, size} = this;
      this.$api.HTTP_Search(keyword, page, size).then((res) => {
        this.list = res.list;
        this.total = res.total;
        this.setHistory(keyword);
      });
    },
    getSearchHistory(a) {
      this.keyword = a;
      this.getSearch();
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 1200px;
  min-height: 400px;

  &-search {
    width: 800px;
    margin: 0 auto 20px;
  }

  &-list {
    .list {
      background: #fff;
      padding: 0 20px;
      margin: 16px auto;

      li {
        padding: 55px 0 15px;
        border-bottom: 1px solid #ebebeb;
        cursor: pointer;

        .itemInfo {
          width: 990px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .title {
            font-size: 18px;
            color: #000;
            margin-bottom: 15px;
          }

          .des {
            height: 56px;
            font-size: 12px;
            color: #666;
            overflow: hidden;
            line-height: 1.2;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }

          .date {
            font-size: 14px;
            color: #333;
          }
        }
      }
    }
  }
}

.searchhistoey {
  li {
    margin-right: 20px;
    color: #A31D2B;
  }
}
</style>