<!-- 培训动态 -->
<template>
  <div class="detail">
    <!-- banner -->
    <base-banner id="43"></base-banner>
    <base-detail
      :title="detail.title"
      :date="detail.date"
      :img="detail.default_img"
      :content="detail.content"
    ></base-detail>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      detail: {},
    };
  },
  watch: {
    //监听路由
    "$route.query.id": function () {
      this.getInfoFn();
    },
  },
  mounted() {
    this.getInfoFn();
  },
  methods: {
    getInfoFn() {
      this.$api.HTTP_trainInfo(this.$route.query.id).then((res) => {
        this.detail = res;
      });
    }
    },
};
</script>