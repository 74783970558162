<template>
  <div>
    <!-- 首页轮播图 -->
    <el-carousel :interval="3000" :loop="true" arrow="always" height="580px">
      <el-carousel-item v-for="(item,i) in slide" :key="i">
        <div class="banner" @click="goPath3(item.url)">
          <img :src="item.default_img" alt style="width: 100%"/>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="home">
      <div class="real-time-info">
        <!-- 新闻资讯 -->
        <div class="box box1">
          <div class="box-title flex-between">
            <div class="box-tag flex-start">
              <img src="~images/icon2.png" alt />
              新闻资讯
            </div>
            <div @click="$router.push('/news')" class="seeMore flex-start">
              查看更多
              <img src="~images/more.png" />
            </div>
          </div>
          <div class="slide flex-between">
            <div
              v-for="item in xxxw"
              :key="item.id"
              @click="goPath(item,'newsDetail')"
              class="slide-item"
            >
              <img :src="item.default_img" alt />
              <div class="title">{{item.title}}</div>
            </div>
          </div>
        </div>
        <!-- 院部动态 -->
        <div class="box box2">
          <div class="box-title flex-between">
            <div class="box-tag flex-start">
              <img src="~images/icon3.png" alt />
              院部动态
            </div>
            <div @click="$router.push('/ybdt')" class="seeMore flex-start">
              查看更多
              <img src="~images/more.png" />
            </div>
          </div>
          <ul class="ybdt">
            <li v-for="a in ybdt" @click="goPath(a,'ybdtDetail')" :key="a.id">
              <p class="p1">{{a.title}}</p>
              <p class="p2">{{a.created_at}}</p>
            </li>
          </ul>
        </div>
      </div>
      <!-- 时政要闻 -->
      <div class="szywBox">
        <div class="szywHead flex-between">
          <div class="title flex-start">
            <img src="~images/icon4.png" alt />
            时政要闻
          </div>
          <div @click="$router.push('/szyw')" class="seeMore flex-start">
            查看更多
            <img src="~images/more.png" />
          </div>
        </div>
        <ul class="list">
          <li v-for="a in szyw" @click="goPath(a,'szywDetail')" class="flex-start" :key="a.id">
            <div class="date">
              <p class="p1">{{a.created_at.substring(8,10)}}</p>
              <p class="p2">{{a.created_at.substring(0,7)}}</p>
            </div>
            <div class="title">{{a.title}}</div>
          </li>
        </ul>
      </div>
      <!-- 教学研究 -->
      <div class="jxyj-bkzx flex-between">
        <div class="box box3">
          <div class="box-title flex-between">
            <div class="box-tag flex-start">
              <img src="~images/icon5.png" alt />
              教学研究
            </div>
            <div @click="$router.push({name:'jxyj'})" class="seeMore flex-start">
              查看更多
              <img src="~images/more.png" />
            </div>
          </div>
          <div class="slide flex-between">
            <div
              v-for="item in teachers"
              @click="goPath(item,'jxyjDetail')"
              :key="item.id"
              class="slide-item"
            >
              <img :src="item.default_img" alt />
              <div class="title">{{item.title}}</div>
            </div>
          </div>
        </div>
        <!-- 报考资讯 -->
        <div class="box box4">
          <div class="box-title flex-between">
            <div class="box-tag flex-start">
              <img src="~images/icon6.png" alt />
              报考资讯
            </div>
            <div @click="$router.push({name:'bkzx'})" class="seeMore flex-start">
              查看更多
              <img src="~images/more.png" />
            </div>
          </div>
          <ul class="list">
            <li
              v-for="item in sign"
              @click="goPath(item,'bkzxDetail')"
              :key="item.id"
              class="flex-start"
            >
              <img :src="item.default_img" alt />
              <div class="content">
                <p class="title">{{item.title}}</p>
                <p class="date">{{item.created_at.substring(0,10)}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 校园文化 -->
      <div class="xuwhBox">
        <div class="head-title flex-between">
          <div class="labelTag flex-start">
            <img src="~images/icon7.png" alt />
            校园风貌
          </div>
          <div @click="$router.push({name:'xyfm'})" class="seeMore flex-start">
            查看更多
            <img src="~images/more.png" />
          </div>
        </div>
        <div class="imgs flex-start">
          <div v-for="item in students" @click="goPath(item,'xyfmDetail')" :key="item.id" class="firstImg hvimghover">
            <div class="hvimg" :style="{'background': 'url(' + item.default_img + ')'}"></div>
          </div>
        </div>
          <!-- <div class="secondImg">
          <img @click="$router.push({name:'xywhDetail',query:{id:students[1].id}})" class="img1" :src="students[1].default_img" alt />
          <div class="img2 flex-start">
            <img @click="$router.push({name:'xywhDetail',query:{id:students[2].id}})" :src="students[2].default_img" alt />
            <img @click="$router.push({name:'xywhDetail',query:{id:students[3].id}})" :src="students[3].default_img" alt />
          </div>
          </div>-->
        </div>
      </div>
      <!-- 友情链接 -->
      <!-- <div class="yqljBox">
      <div class="labelTag flex-between">
        友情链接
      </div>
      <ul class="yqljLink">
        <li v-for="(a,i) in links" :key="i">{{a.title}}</li>
      </ul>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      slide: [],
      xxxw: [],
      ybdt: [],
      szyw: [],
      teachers: [],
      students: [],
      sign: [],
      links: [],
    };
  },
  mounted() {
    this.getMainData();
  },
  methods: {
    getMainData() {
      this.$api.HTTP_Home().then((res) => {
        this.slide = res.slide;
        this.xxxw = res.xxxw.slice(0, 2);
        this.ybdt = res.ybdt;
        this.szyw = res.szyw;
        this.teachers = res.teachers.slice(0, 2);
        this.students = res.students.slice(0, 4);
        this.sign = res.sign.slice(0, 2);
        this.links = res.links;
      });
    },
    goPath(item, routerName) {
      if (item.url) {
        window.open(item.url);
      } else {
        this.$router.push({ name: routerName, query: { id: item.id } });
      }
    },
    goPath2(item, routerName) {
      if (item.url) {
        window.open(item.url);
      } else {
        this.$router.push({ name: routerName, query: { id: item.id } });
      }
    },
    goPath3(url) {
      if (url) {
        window.open(url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .is-active {
  .el-carousel__button {
    background: #015571;
  }
}
.home {
  width: 1200px;
  margin: 0 auto;
  /* 首页轮播 */
  .banner {
    width: 100%;
    img {
      width: 100%;
      height: 980px;
    }
  }
  .seeMore {
    cursor: pointer;
  }
  /* 公共头部样式 */
  .box {
    width: 600px;
    min-height: 320px;
    padding: 10px;
    margin-bottom: 40px;
    &-title {
      height: 55px;
      margin-bottom: 10px;
      border-bottom: 3px solid #666666;
      .seeMore {
        font-size: 18px;
        color: #5c5c5c;
        img {
          margin-left: 6px;
        }
      }
    }
    &-tag {
      font-size: 32px;
      color: #000;
      img {
        margin-right: 10px;
      }
    }
  }
  /* 院部动态 */
  .real-time-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;

    /* 新闻资讯 */
    .box1 {
      .slide-item {
        width: 272px;
        height: 220px;
        position: relative;
        cursor: pointer;
        img {
          display: block;
          height: 170px;
        }
        .title {
          height: 50px;
          background: #fff;
          padding: 10px;
          font-size: 12px;
          line-height: 1.2;
          color: #445697;
          box-shadow: 1px 2px 4px 0px rgba(78, 78, 78, 0.5);
        }
      }
    }
    /* 院部动态 */
    .box2 {
      .ybdt {
        padding: 0 10px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 60px;
          cursor: pointer;
          font-size: 20px;
          &:hover {
            text-decoration: underline;
          }
          .p1 {
            width: 400px;
          }
          .p2 {
            width: 130px;
          }
        }
      }
    }
  }
  /* 友情链接 */
  /* 时政要闻 */
  .szywBox {
    margin-bottom: 40px;
    height: 310px;
    padding: 10px;
    background: url(~images/midbackground.png) no-repeat center / 100% 100%;
    cursor: pointer;
    .szywHead {
      height: 60px;
      padding: 0 30px;
      .title {
        font-size: 32px;
        img {
          margin-right: 20px;
        }
      }
      .seeMore {
        font-size: 18px;
        color: #5c5c5c;
        cursor: pointer;
        img {
          margin-left: 6px;
        }
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 30px;
      li {
        height: 110px;
        font-size: 18px;
        .date {
          text-align: center;
          margin-right: 22px;
          .p1 {
            width: 72px;
            height: 44px;
            background: #015571;
            font-size: 33px;
            color: #fff;
            line-height: 44px;
          }
          .p2 {
            width: 72px;
            height: 28px;
            line-height: 28px;
            background: #ffffff;
            font-size: 16px;
            color: #383838;
          }
        }
        .title {
          width: 400px;
        }
      }
    }
  }

  /* 教学研究///报考资讯 */
  .jxyj-bkzx {
    .box3 {
      width: 600px;
      padding: 10px;
      cursor: pointer;
      .slide-item {
        width: 272px;
        height: 220px;
        position: relative;
        img {
          display: block;
          height: 170px;
        }
        .title {
          height: 50px;
          background: #fff;
          padding: 10px;
          font-size: 12px;
          line-height: 1.2;
          color: #445697;
          box-shadow: 1px 2px 4px 0px rgba(78, 78, 78, 0.5);
        }
      }
    }
    .box4 {
      cursor: pointer;
      .list {
        li {
          margin-bottom: 20px;
        }
        img {
          width: 152px;
          height: 90px;
          margin-right: 18px;
        }
        .content {
          width: 380px;
          .title {
            font-size: 18px;
            color: #000;
            margin-bottom: 15px;
          }
          .des {
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }

  /* 校园风貌 */
  .xuwhBox {
    cursor: pointer;
    .head-title {
      height: 100px;
      .labelTag {
        height: 60px;
        img {
          margin-right: 13px;
        }
        font-size: 32px;
      }
      .seeMore {
        font-size: 18px;
        color: #5c5c5c;
        img {
          margin-left: 6px;
        }
      }
    }
    .hvimg{
      display: block;
      width: 95%;
      height: 100%;
      background-size: cover !important;
      transition: 1s;
    }
    .hvimghover:hover .hvimg{
      transform: scale(1.1);
    }
    .imgs {
      overflow: hidden;
      .firstImg {
        width: 100%;
        height: 200px;
        padding-left: 20px;
        img {
          width: 100%;
          height: 160px;
          padding-left: 20px;
        }
      }
      .secondImg {
        width: 600px;
        .img1 {
          width: 600px;
          height: 224px;
        }
        .img2 {
          img {
            width: 331px;
            height: 110px;
          }
        }
      }
    }
  }
  /* 友情链接 */
  .yqljLink {
    padding: 10px 10px 30px;
    display: flex;
    align-items: center;
    li {
      margin-right: 20px;
      text-decoration: underline;
      cursor: pointer;
      color: #666;
      position: relative;
      &::after {
        display: block;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #666;
        position: absolute;
        left: -10px;
        top: 50%;
        margin-top: -3px;
      }
    }
  }
}
</style>