import req from '../api.js'
/* 培训动态列表 */
export const HTTP_trainNews = (page, size) => req('/api/train/news', { page, size })
/* 培训动态详情 */
export const HTTP_trainInfo = (id) => req('/api/train/info', { id })

/* 培训项目列表 */
export const HTTP_trainIndex = (page, size) => req('/api/train/index', { page, size })
/* 培训项目详情 */
export const HTTP_trainDetail = (id) => req('/api/train/detail', { id })

/* 报名流程列表 */
export const HTTP_trainSign = (page, size) => req('/api/train/sign', { page, size })
/* 报名流程详情 */
export const HTTP_trainSigninfo = (id) => req('/api/train/signinfo', { id })

/* 联系方式 */
export const HTTP_trainContact = (id) => req('/api/train/contact', { id })
