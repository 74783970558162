/**
 * @根据当前跳转的路由设置显示在浏览器标签的title
 * @param {object} route 路由对象
 */
export const getRouteTitleHandled = (route) => {
  const router = { ...route }
  const meta = { ...route.meta }
  let title = ''
  if (meta.title) {
    if (typeof meta.title === 'function') {
      meta.__titleIsFunction__ = true
      title = meta.title(router)
    } else {
      title = meta.title
    }
  }
  meta.title = title
  router.meta = meta
  return router
}
/**
 * 
 * @param {object} item 提示对象
 * @param {this} vm 
 * @returns 提示内容
 */
export const showTitle = (item, vm) => {
  let { title } = item.meta
  if (!title) return
  title = (item.meta && item.meta.title) || item.name
  return title
}

/**
 * @根据当前跳转的路由设置显示在浏览器标签的title
 * @param {object} routeItem 路由对象
 * @param {string} title     APP title
 * @param {object} vm Vue实例
 */
export const setTitle = (routeItem, title) => {
  const handledRoute = getRouteTitleHandled(routeItem)
  const pageTitle = showTitle(handledRoute)
  const resTitle = pageTitle ? `${pageTitle}` : title
  return resTitle
}

/**
 * 获取文件后缀名
 * @param {object} file
 */
export const getFileSuffix = file => {
  const fileName = file.name
  const suffix = fileName.substring(fileName.lastIndexOf('.') + 1)
  return suffix
}

/**
 * 下划线转换驼峰
 * @param    {string}   name []
 */
export const toHump = name => {
  return name.replace(/\_(\w)/g, function (all, letter) {
    return letter.toUpperCase()
  })
}

/**
 * 驼峰转换下划线
 * @param    {string}   name []
 */
export const toLine = name => {
  return name.replace(/([A-Z])/g, '_$1').toLowerCase()
}

/**
 * 转成驼峰或者下划线
 * @param    {object}   obj  []
 * @param    {number}   type [1驼峰，2下划线]
 */
export const fmtObjKey = (obj, type = 1) => {
  const newObj = {}
  for (const [key, value] of Object.entries(obj)) {
    const _key = type == 1 ? toHump(key) : toLine(key)
    newObj[_key] = value
  }
  return newObj
}

/**
 * 对象转数组对象
 * @param    {object}   obj  []
 *
 * eg: { i: "Ios", a: "Android" } => [{ key: "i", value: "Ios" }, { a: "i", value: "Android" }]
 */
export const transObjToArr = (obj) => {
  return Object.entries(obj).map(([key, value]) => ({ key, value }))
}

/**
 * 数据类型判断
 * @param {object} obj
 */
export const typeOf = obj => {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}

/**
 * 深度比较两个对象是否相同
 * @param {object} oldData
 * @param {object} newData
 */
export const equalsObj = (oldData, newData) => {
  if (oldData === newData) return true
  if (typeOf(oldData) === 'object' && typeOf(newData) === 'object' && Object.keys(oldData).length === Object.keys(newData).length) {
    for (const key in oldData) {
      if (Object.prototype.hasOwnProperty.call(oldData, key)) {
        if (!equalsObj(oldData[key], newData[key])) { return false }
      }
    }
  } else if (typeOf(oldData) === 'array' && typeOf(oldData) === 'array' && oldData.length === newData.length) {
    for (let i = 0, length = oldData.length; i < length; i++) {
      if (!equalsObj(oldData[i], newData[i])) { return false }
    }
  } else {
    return false
  }
  return true
}

/**
 * 深度merge
 * @param {object} target
 * @param {object} source
 */
export const deepMerge = (target, source) => {
  let key
  for (key in source) {
    target[key] =
      target[key] && target[key].toString() === '[object Object]'
        ? deepMerge(target[key], source[key])
        : (target[key] = source[key])
  }
  return target
}

/**
 * 深度clone
 * @param {object} source
 */
export const deepClone = source => {
  const targetObj = source.constructor === Array ? [] : {} // 判断复制的目标是数组还是对象
  for (const keys in source) {
    if (Object.prototype.hasOwnProperty.call(source, keys)) {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = source[keys].constructor === Array ? [] : {}
        targetObj[keys] = deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    }
  }
  return targetObj
}

/**
 * 节流
 * @param {function} fn [方法名]
 * @param {number}   t  [延迟时间]
 */
export const throttle = (fn, t = 1000) => {
  if (typeof fn !== 'function') throw new Error('第一个参数必须是方法')
  const _fn = fn
  let time = null
  let first = true
  return function () {
    const arg = arguments
    const _this = this
    if (first) {
      _fn.apply(_this, arg)
      first = false
      return
    }
    if (time) return
    time = setTimeout(function () {
      setTimeout(time)
      time = null
      _fn.apply(_this, arg)
    }, t)
  }
}

/**
 * 防抖
 * @param    {function}  fn []
 * @param    {number}    t  []
 * @param    {boolean}   immediate  []
 */
export const debounce = (fn, t=1000, immediate = true) => {
  if (typeof fn !== 'function') throw new Error('第一个参数必须是方法')
  const _fn = fn
  let timer
  return function () {
    if (timer) clearTimeout(timer)
    if (immediate) {
      // 如果已经执行过，不再执行
      var callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, t)
      if (callNow) {
        _fn.apply(this, arguments)
      }
    } else {
      timer = setTimeout(() => {
        _fn.apply(this, arguments)
      }, t)
    }
  }
}

/**
 * 多维数组扁平化处理
 * @param {array} arr
 */
export const flatten = arr => {
  while (arr.some(item => Array.isArray(item))) {
    arr = [].concat(...arr)
  }
  return arr
}

/**
 * @解决事件监听兼容性问题
 * @param {object} obj对象
 * @param {string} type时间类型,不带'on'前缀
 * @param {function} callback事件处理程序
 */
export const addEvent = (element, type, handler) => {
  if (element.addEventListener) {
    // W3C内核
    element.addEventListener(type, handler, false)
  } else if (element.attachEvent) {
    // IE内核
    element.attachEvent('on' + type, handler)
  } else {
    element['on' + type] = handler
  }
}

/**
 * 解决移除事件监听兼容性问题
 * @param {object} obj对象
 * @param {string} type时间类型,不带'on'前缀
 * @param {function} callback事件处理程序
 */
export const removeEvent = (element, type, handler) => {
  if (element.removeEventListener) {
    // W3C内核
    element.removeEventListener(type, handler)
  } else {
    // IE内核
    element.detachEvent('on' + type, handler)
  }
}

/**
 * 时间加时长转时间段
 */
export const timeAddTimeLengthToTimeinterval = (time, timeLength) => {
  const hours = timeLength < 60 ? 0 : Math.floor(timeLength / 60)
  const minutes = timeLength % 60
  let newHourse = +time.split(':')[0] + hours
  let newMinutes = +time.split(':')[1] + minutes
  if (newMinutes >= 60) {
    newMinutes = 0
    newHourse++
  }
  const newTime = (newHourse >= 10 ? newHourse : ('0' + newHourse)) + ':' + (newMinutes >= 10 ? newMinutes : ('0' + newMinutes))
  const timeinterval = time + '-' + newTime
  return timeinterval
}

/**
 * 
 * @param {Arrray} arr 
 * @returns 数组和的值
 */
export const arrSum = (arr) => {
  let sum = 0
  if (arr.length) {
    sum = arr.reduce((prev, next) => prev + next)
  }
  return sum
}

/**
 * 数据脱敏
 * @param {string} val 
 * @returns 
 */
 export const desensitization = (val) => {
  if (val && val.length) {
    const len = val.length
    if (len === 1) {
      return '*';
    } else if (len === 2) {
      return val[0] + '*';
    } else if (len === 3) {
      return val[0] + '*' + val[2];
    } else if (len > 3 && len < 6) {
      const newVal = val.substring(1, len - 1);
      let newValChange = []
      for (let i = 0; i < newVal.length; i++) {
        newValChange.push('*');
      }
      return val[0] + newValChange.join('') + val[len - 1]
    } else if (len >= 6 && len < 8) {
      const newVal = val.substring(2, len - 2);
      let newValChange = []
      for (let i = 0; i < newVal.length; i++) {
        newValChange.push('*');
      }
      return val[0] + val[1] + newValChange.join('') + val[len - 2] + val[len - 1]
    } else {
      const newVal = val.substring(3, len - 3);
      let newValChange = []
      for (let i = 0; i < newVal.length; i++) {
        newValChange.push('*');
      }
      return val[0] + val[1] + val[2] + newValChange.join('') + val[len - 3] + val[len - 2] + val[len - 1]
    }
  }
}