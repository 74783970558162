/*
 * @Descripttion:
 * @version: 0.0.1
 * @Author: liyang.guan
 * @Date: 2020-10-30 10:33:56
 * @LastEditors: liyang.guan
 * @LastEditTime: 2020-10-30 17:37:10
 */
switch (process.env.NODE_ENV) {
  case 'development':
    module.exports = file => require('@/views/' + file + '.vue').default
    break
  case 'production':
    module.exports = file => () => import('@/views/' + file + '.vue')
    break
}
