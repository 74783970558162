<template>
  <!-- @contextmenu.prevent -->
  <div id="app">
    <div v-if="!network" class="nonet">网络中断，请检查网络连接</div>
    <div class="navLogo flex-center">
      <v-header></v-header>
    </div>
    <!-- 头部 -->
    <div class="header flex-center">
      <navbar />
    </div>
    <!-- <transition :name="transitionName">
      <router-view
        v-if="network"
        v-wechat-title="$route.meta.title"
        class="root"
      />
    </transition>-->
    <!-- 主体页面内容 -->
    <div class="main-container">
      <app-main />
    </div>
    <!-- 底部 -->
    <div class="footer">
      <v-footer :base-data="baseData" />
    </div>
  </div>
</template>
<script>
import { addEvent, removeEvent } from "@/utils/common";
import { Session } from "@/utils/storage";
import { AppMain, VHeader, Navbar, VFooter, Ad } from "@/components";
export default {
  name: "App",
  components: {
    AppMain,
    VHeader,
    Navbar,
    VFooter,
    Ad,
  },
  data() {
    return {
      network: true, // 默认有网11
      transitionName: "forward",
      scaneIdCardInfo: "",
      baseData: {},
    };
  },
  mounted() {
    addEvent(window, "offline", () => {
      this.network = false;
      Session.clear();
    });
    addEvent(window, "online", () => {
      this.network = true;
    });
    this.$api.HTTP_Index().then((res) => {
      this.baseData = res;
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", res.keyword);
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", res.desc);
      // head[0].appendChild(meta);
    });
  },
  destroyed() {
    removeEvent(window, "offline");
    removeEvent(window, "online");
    Session.clear();
  },
};
</script>
<style lang="scss" scoped>
#app {
  position: relative;
  height: 100%;
  .nonet {
    padding-top: 200px;
    text-align: center;
    font-size: 16px;
  }
  .main-container {
    min-width: 1200px;
    margin: 0 auto;
    background: #f5f5f5;
  }
  .navLogo {
    width: 100%;
    height: 150px;
    position: fixed;
    top: 0;
    z-index: 10;
    background: url(~images/topbackground.png) no-repeat center/100% 100%;
  }
  .header {
    height: 100px;
    width: 100%;
    background: #fff;
    position: fixed;
    top: 150px;
    z-index: 10;
  }

  .footer {
    min-width: 1200px;
    z-index: 10;
    height: 305px;
    background: url(~images/bottombackground.png) no-repeat center / 100% 100%;
  }
}
</style>
