import Vue from 'vue'
import Router from 'vue-router'
// import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
import RouterModule from './modules/base'
import { titlePrefix, routeMode, routeBase, devToken,baseURL } from '@/config'
// import { setTitle } from '@/utils/common'
import { Cookie, Session } from '@/utils/storage'
Vue.use(Router)

// vue-router ≥3.0版本回调形式以及改成promise api的形式了，返回的是一个promise，如果没有捕获到错误，控制台始终会出现警告，
const routeTypes = ['push', 'replace']
for (const me of routeTypes) {
  const original = Router.prototype[me]
  Router.prototype[me] = function (location) {
    return original.call(this, location).catch(err => err)
  }
}
/**
 * 跳页时，取消上页请求
 * @param {*} callback
 */
const cancelLastReq = (callback = () => { }) => {
  const axiosPromiseArr = window.__axiosPromiseArr || []
  if (!!axiosPromiseArr && axiosPromiseArr.length) {
    let len = axiosPromiseArr.length
    while (len--) {
      axiosPromiseArr[len].cancel('取消相同接口请求') // 取消同时执行的多次请求
      axiosPromiseArr.splice(len, 1)
    }
  }
  callback && callback()
}
const router = new Router({
  mode: routeMode,
  base: routeBase, // url前缀
  routes: RouterModule,
  scrollBehavior(to, from, savedPosition) {
    // history模式时生效
    if (savedPosition) {
      return savedPosition
    } else {
      // 异步滚动操作
      return new Promise(resolve => {
        setTimeout(() => {
          resolve({
            x: 0,
            y: 0
          })
        }, 0)
      })
    }
  }
})
const whiteList = ['/login', '/', '/home']
/**
 * 路由前置拦截器
 */
router.beforeEach((to, from, next) => {
  NProgress.start()
  cancelLastReq(() => {
    // if (devToken) {
    //   Cookie.set('js_api_token', devToken)
    //   Session.set('js_api_token', devToken)
    // }
    if (to.meta.is_login) {
      if (Session.get('js_api_token')) {
        NProgress.done()
        next()
      } else {
        let js_api_token = Cookie.get('js_api_token')
        if (js_api_token) {
          Session.set('js_api_token', js_api_token)
          NProgress.done()
          next()
        } else {
          NProgress.done()
          let path = baseURL+to.fullPath;
          window.location.href = "https://xzjsxy.net/webwx?url=" + path;
        }
      }
    } else {
      NProgress.done();
      // let js_api_token = Cookie.get('js_api_token')
      // if (js_api_token) {
      //   Session.set('js_api_token', js_api_token)
      // }
      next()
    }
  })
})

router.afterEach((to, from) => {
  NProgress.done()
  window.scrollTo(0, 0)
  // const newTitle = title;
  // const resTitle = setTitle(to, newTitle)
  window.document.title = titlePrefix + to.meta.title
})

export default router
