const _import = require('../config/import')
const pxjdRoute = [
  {
    path: '/pxjd',
    component: _import('modules/pxjd/index'),
    name: 'pxjd',
    redirect: { name: 'pxxm' },
    meta: { title: '培训鉴定', nav: true },
    children: [
      {
        path: 'pxxm',
        component: _import('modules/pxjd/pxxm'),
        name: 'pxxm',
        meta: { title: '社会化短期培训', nav: true, isBreadcrumb: true },
      },
      {
        path: 'pxxmDetail',
        component: _import('modules/pxjd/pxxmDetail'),
        name: 'pxxmDetail',
        meta: { title: '社会化短期培训', nav: false, isBreadcrumb: true },
      },
      {
        path: 'pxdt',
        component: _import('modules/pxjd/pxdt'),
        name: 'pxdt',
        meta: { title: '技能评价', nav: true, isBreadcrumb: true },
      },
      {
        path: 'pxdtDetail',
        component: _import('modules/pxjd/pxdtDetail'),
        name: 'pxdtDetail',
        meta: { title: '技能评价', nav: false, isBreadcrumb: true },
      },
      // {
      //   path: 'bmlc',
      //   component: _import('modules/pxjd/bmlc'),
      //   name: 'bmlc',
      //   meta: { title: '报名流程', nav: true, isBreadcrumb: true },
      // },
      // {
      //   path: 'bmlcDetail',
      //   component: _import('modules/pxjd/bmlcDetail'),
      //   name: 'bmlcDetail',
      //   meta: { title: '报名流程', nav: false, isBreadcrumb: true },
      // },
      {
        path: 'pxjdlxfs',
        component: _import('modules/pxjd/lxfs'),
        name: 'pxjdlxfs',
        meta: { title: '联系方式', nav: true, isBreadcrumb: true },
      }
    ]
  },
]
export default pxjdRoute