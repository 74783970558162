import req from '../api.js'

/* 学生资助列表 */
export const HTTP_signXszz = (page, size) => req('/api/student/xszz', { page, size })
/* 学生资助详情 */
export const HTTP_Xszzinfo = (id) => req('/api/student/xszzinfo', { id })

/* 校园文化列表 */
export const HTTP_studentIndex = (page, size) => req('/api/student/index', { page, size })
/* 校园文化详情 */
export const HTTP_studentDetail = (id) => req('/api/student/detail', { id })

/* 思政教育列表 */
export const HTTP_studentiDeological = (page, size) => req('/api/student/ideological', { page, size })
/* 思政教育详情 */
export const HTTP_studentIdeologicalinfo = (id) => req('/api/student/ideologicalinfo', { id })

/* 国防教育列表 */
export const HTTP_studentiDefence = (page, size) => req('/api/student/defence', { page, size })
/* 国防教育详情 */
export const HTTP_studentDefenceinfo = (id) => req('/api/student/defenceinfo', { id })

/* 心理健康教育列表 */
export const HTTP_studentiMental = (page, size) => req('/api/student/mental', { page, size })
/* 心理健康教育详情 */
export const HTTP_studentMentalinfo = (id) => req('/api/student/mentalinfo', { id })