const _import = require('../config/import')
const ddjsRoute = [
  {
    path: '/ddjs',
    component: _import('modules/ddjs/index'),
    name: 'ddjs',
    meta: { title: '党的建设', nav: true },
    redirect: {
      name: 'xfqz'
    },
    children: [
      {
        path: 'xfqz',
        component: _import('modules/ddjs/xfqz'),
        name: 'xfqz',
        meta: { title: '先锋旗帜', nav: true, isBreadcrumb: true },
      },
      {
        path: 'xfqzDetail',
        component: _import('modules/ddjs/xfqzDetail'),
        name: 'xfqzDetail',
        meta: { title: '先锋旗帜', nav: false, isBreadcrumb: true },
      },
      {
        path: 'dflz',
        component: _import('modules/ddjs/dflz'),
        name: 'dflz',
        meta: { title: '党风廉政', nav: true, isBreadcrumb: true },
      },
      {
        path: 'dflzDetail',
        component: _import('modules/ddjs/dflzDetail'),
        name: 'dflzDetail',
        meta: { title: '党风廉政', nav: false, isBreadcrumb: true },
      },
      {
        path: 'djhd',
        component: _import('modules/ddjs/djhd'),
        name: 'djhd',
        meta: { title: '党建活动', nav: true, isBreadcrumb: true },
      },
      {
        path: 'djhdDetail',
        component: _import('modules/ddjs/djhdDetail'),
        name: 'djhdDetail',
        meta: { title: '党建活动', nav: false, isBreadcrumb: true },
      },
    ]
  }
]
export default ddjsRoute