<!-- 学生资助 -->
<template>
  <div class="wrap">
    <base-banner id="38"></base-banner>
    <!-- 列表 -->
    <ul class="list">
      <li v-for="a in list" :key="a.id" @click="goRoute(a)" class="flex-between">
        <img :src="a.default_img" alt />
        <div class="itemInfo">
          <p class="title">{{a.title}}</p>
          <p class="des">{{a.des}}</p>
          <p class="date">{{a.date}}</p>
        </div>
      </li>
    </ul>
    <!-- 分页 -->
    <div class="pagination flex-center">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="getPageNum"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      page: 0,
      size: 10,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goRoute(a) {
      if (a.url) {
        window.open(a.url);
      } else {
        this.$router.push({
          name: "xszzDetail",
          query: { id: a.id },
        });
      }
    },
    getList() {
      let { page, size } = this;
      this.$api.HTTP_signXszz(page, size).then((res) => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    getPageNum(e) {
      this.page = e-1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap{
  padding-bottom: 20px;
}
.list {
  background: #fff;
  padding: 0 20px;
  width: 1200px;
  margin: 16px auto;
  li {
    padding: 55px 0 15px;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    img {
      width: 218px;
      height: 122px;
      margin-right: 20px;
    }
    .itemInfo {
      width: 990px;
      height: 122px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .title {
        font-size: 14px;
        color: #000;
      }
      .des {
        height: 56px;
        font-size: 12px;
        color: #666;
        overflow: hidden;
        line-height: 1.2;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .date {
        font-size: 12px;
        color: #333;
      }
    }
  }
}
</style>>