<template>
  <div class="box">
    <div class="content">
      <ul class="menu">
        <router-link
          tag="li"
          v-for="(a, i) in navMenu"
          :to="{name:a.name}"
          :key="i"
        >{{a.meta.title}}</router-link>
      </ul>
      <div class="lxwm flex-start">
        <div>
          <p class="title">联系我们</p>
          <p>学校电话：{{baseData.mobile}}</p>
          <p>学校邮箱：{{baseData.email}}</p>
          <p>学校地址：{{baseData.address}}</p>
        </div>
        <div class="qrcode">
          <img src="~images/qrcode.jpg" alt />
          <p>关注了解更多</p>
        </div>
      </div>
      <div style="margin-top: 10px;text-align: center">
        <a href="https://xyt.xcc.cn/getpcInfo?sn=1724047846698618880&language=CN&certType=8&url=*.xzjsxy.net" target="_blank"
           style="position: relative;display: inline-block;height: 38px;">
          <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div>
          <embed src="https://program.xinchacha.com/web/1724047846698618880=*.xzjsxy.net.svg" width="103" height="38"
                 type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/>
        </a>
      </div>
    </div>

    <div @click="beian" class="beian">{{baseData.beian}}</div>

  </div>
</template>

<script>
export default {
  props: {
    baseData: {
      type: Object,
      default: {},
    }
  },
  data() {
    return {
      navMenu: [],
    };
  },
  mounted() {
    this.navMenu = this.$router.options.routes.filter((o) => o.meta.nav);
  },
  methods: {
    beian() {
      window.open("https://beian.miit.gov.cn");
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 1200px;
  margin: 10px auto 0;
  padding-top: 60px;
  // padding-bottom: 20px;
  color: #fff;
  // background: url(~images/bottombackground.png) no-repeat center / 100% 100%;
  .content {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    align-items: center;
  }
  .menu {
    width: 320px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: 80px;
      font-size: 16px;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }
  .lxwm {
    font-size: 16px;
    p {
      margin-bottom: 15px;
    }
    .qrcode {
      margin-left: 20px;
      img {
        width: 129px;
        height: 129px;
        margin-bottom: 10px;
      }
      p {
        text-align: center;
      }
    }
  }
  .beian {
    text-align: center;
    font-size: 17px;
    cursor: pointer;
  }
}
</style>
