const _import = require('../config/import')
const zsjyRoute = [
    {
        path: '/zsjy',
        component: _import('modules/zsjy/index'),
        name: 'zsjy',
        meta: { title: '招生就业', nav: true },
        redirect:{name:'bkzx'},
        children: [
            // {
            //     path: 'zsjh',
            //     component: _import('modules/zsjy/zsjh'),
            //     name: 'zsjh',
            //     meta: { title: '招生简章', nav: true, isBreadcrumb: true },
            // },
            // {
            //     path: 'zsjhDetail',
            //     component: _import('modules/zsjy/zsjhDetail'),
            //     name: 'zsjhDetail',
            //     meta: { title: '招生简章', nav: false, isBreadcrumb: true },
            // },
            {
                path: 'bkzx',
                component: _import('modules/zsjy/bkzx'),
                name: 'bkzx',
                meta: { title: '招生资讯', nav: true, isBreadcrumb: true },
            }, 
            {
                path: 'bkzxDetail',
                component: _import('modules/zsjy/bkzxDetail'),
                name: 'bkzxDetail',
                meta: { title: '招生资讯', nav: false, isBreadcrumb: true },
            },
            {
                path: 'wsbm',
                component: _import('modules/zsjy/wsbm'),
                name: 'wsbm',
                meta: { title: '网上报名',is_login:true, nav: true },
            },
            {
                path: 'wsbm1',
                component: _import('modules/zsjy/wsbm1'),
                name: 'wsbm1',
                meta: { title: '报名-基本信息',is_login:true, nav: false },
            },
            {
                path: 'wsbm2',
                component: _import('modules/zsjy/wsbm2'),
                name: 'wsbm2',
                meta: { title: '报名-毕业概况',is_login:true, nav: false },
            },
            {
                path: 'wsbm3',
                component: _import('modules/zsjy/wsbm3'),
                name: 'wsbm3',
                meta: { title: '报名-报读专业',is_login:true, nav: false },
            },
            {
                path: 'wsbm4',
                component: _import('modules/zsjy/wsbm4'),
                name: 'wsbm4',
                meta: { title: '报名-家庭成员',is_login:true, nav: false },
            },
            {
                path: 'xssx',
                component: _import('modules/zsjy/xssx'),
                name: 'xssx',
                meta: { title: '学生实习', nav: true, isBreadcrumb: true },
            }, 
            {
                path: 'xssxDetail',
                component: _import('modules/zsjy/xssxDetail'),
                name: 'xssxDetail',
                meta: { title: '学生实习', nav: false, isBreadcrumb: true },
            },
            {
                path: 'jycy',
                component: _import('modules/zsjy/jycy'),
                name: 'jycy',
                meta: { title: '就业信息', nav: true, isBreadcrumb: true },
            }, 
            {
                path: 'jycyDetail',
                component: _import('modules/zsjy/jycyDetail'),
                name: 'jycyDetail',
                meta: { title: '就业信息', nav: false, isBreadcrumb: true },
            },
            {
                path: 'lxfs',
                component: _import('modules/zsjy/lxfs'),
                name: 'lxfs',
                meta: { title: '联系方式', nav: true },
            }
        ]
    },
]
export default zsjyRoute