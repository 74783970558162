<!-- 教师风采 -->
<template>
  <div class="wrap">
    <base-banner id="31"></base-banner>
    <!-- 列表 -->
    <el-row class="list" :gutter="20"  style="margin: 0 auto">
      <el-col v-for="a in list" :key="a.id" :span="6">
        <div class="list-item" @click="goRoute(a)">
          <img :src="a.default_img" alt />
          <p class="title">{{a.title}}</p>
        </div>
      </el-col>
    </el-row>
    <!-- 分页 -->
    <div class="pagination flex-center">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="getPageNum"
        :page-size="size"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      page: 0,
      size: 12,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goRoute(a) {
      if (a.url) {
        window.open(a.url);
      } else {
        this.$router.push({
          name: "jsfcDetail",
          query: { id: a.id },
        });
      }
    },
    getList() {
      let { page, size } = this;
      this.$api.HTTP_teachMine(page, size).then((res) => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    getPageNum(e) {
      this.page = e-1;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap{
  padding-bottom: 20px;
}
.list {
  background: #fff;
  padding: 0 20px;
  width: 1200px;
  &-item {
    padding: 55px 0 15px;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    img {
      width: 218px;
      height: 122px;
      margin: 0 auto;
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 14px;
      color: #000;
    }
  }
}
</style>>
