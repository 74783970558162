const _import = require('../config/import')
const xbjsRoute = [
  {
    path: '/xbjs',
    component: _import('modules/xbjs/index'),
    name: 'xbjs',
    redirect: {
      name: 'subZnjsXbjs',
      query: {
        cate: 'znjscy'
      }
    },
    meta: { title: '系部建设', nav: true, isBreadcrumb: true },
    children: [
      {
        path: 'subZnjsXbjs',
        name: 'subZnjsXbjs',
        meta: { title: '智能技术', nav: true, isBreadcrumb: true },
        component: _import('modules/xbjs/subZnjsXbjs'),
      },
      {
        path: 'subSzxxXbjs',
        name: 'subSzxxXbjs',
        meta: { title: '数字信息', nav: true, isBreadcrumb: true },
        component: _import('modules/xbjs/subSzxxXbjs'),
      },
      {
        path: 'subJtyqcXbjs',
        name: 'subJtyqcXbjs',
        meta: { title: '交通与汽车', nav: true, isBreadcrumb: true },
        component: _import('modules/xbjs/subJtyqcXbjs'),
      },
      {
        path: 'subHjynyXbjs',
        name: 'subHjynyXbjs',
        meta: { title: '环境与能源', nav: true, isBreadcrumb: true },
        component: _import('modules/xbjs/subHjynyXbjs'),
      },
      {
        path: 'subMzwhXbjs',
        name: 'subMzwhXbjs',
        meta: { title: '民族文化', nav: true, isBreadcrumb: true },
        component: _import('modules/xbjs/subMzwhXbjs'),
      },
      {
        path: 'subLyysmXbjs',
        name: 'subLyysmXbjs',
        meta: { title: '旅游与商贸', nav: true, isBreadcrumb: true },
        component: _import('modules/xbjs/subLyysmXbjs'),
      },
      {
        path: 'subJcwhbXbjs',
        name: 'subJcwhbXbjs',
        meta: { title: '基础文化部', nav: true, isBreadcrumb: true },
        component: _import('modules/xbjs/subJcwhbXbjs'),
      },
      {
        path: 'zyjsList',
        component: _import('modules/xbjs/zyjsList'),
        name: 'zyjsList',
        meta: { title: '专业建设', nav: false, isBreadcrumb: true },
      },
      {
        path: 'zyjsDetail',
        component: _import('modules/xbjs/zyjsDetail'),
        name: 'zyjsDetail',
        meta: { title: '专业建设', nav: false, isBreadcrumb: true },
      },
      {
        path: 'jsfcList',
        component: _import('modules/xbjs/jsfcList'),
        name: 'jsfcList',
        meta: { title: '教师风采', nav: false, isBreadcrumb: true },
      },
      {
        path: 'jsfcDetail',
        component: _import('modules/xbjs/jsfcDetail'),
        name: 'jsfcDetail',
        meta: { title: '教师风采', nav: false, isBreadcrumb: true },
      },
      {
        path: 'sshdList',
        component: _import('modules/xbjs/sshdList'),
        name: 'sshdList',
        meta: { title: '师生活动', nav: false, isBreadcrumb: true },
      },
      {
        path: 'sshdDetail',
        component: _import('modules/xbjs/sshdDetail'),
        name: 'sshdDetail',
        meta: { title: '师生活动', nav: false, isBreadcrumb: true },
      },
      {
        path: 'ybdtList',
        component: _import('modules/xbjs/ybdtList'),
        name: 'ybdtList',
        meta: { title: '院部动态', nav: false, isBreadcrumb: true },
      },
      {
        path: 'ybdtDetail',
        component: _import('modules/xbjs/ybdtDetail'),
        name: 'ybdtDetail',
        meta: { title: '院部动态', nav: false, isBreadcrumb: true },
      },
      {
        path: 'znjs',
        component: _import('modules/xbjs/znjs'),
        name: 'znjs',
        meta: { title: '智能技术', nav: false, isBreadcrumb: true },
      },
      {
        path: 'znjsDetail',
        component: _import('modules/xbjs/znjsDetail'),
        name: 'znjsDetail',
        meta: { title: '智能技术', nav: false, isBreadcrumb: true },
      },
      {
        path: 'szxx',
        component: _import('modules/xbjs/szxx'),
        name: 'szxx',
        meta: { title: '数字信息', nav: false, isBreadcrumb: true },
      },
      {
        path: 'szxxDetail',
        component: _import('modules/xbjs/szxxDetail'),
        name: 'szxxDetail',
        meta: { title: '数字信息', nav: false, isBreadcrumb: true },
      },
      {
        path: 'jtyqc',
        component: _import('modules/xbjs/jtyqc'),
        name: 'jtyqc',
        meta: { title: '交通与汽车', nav: false, isBreadcrumb: true },
      },
      {
        path: 'jtyqcDetail',
        component: _import('modules/xbjs/jtyqcDetail'),
        name: 'jtyqcDetail',
        meta: { title: '交通与汽车', nav: false, isBreadcrumb: true },
      },
      {
        path: 'hjyny',
        component: _import('modules/xbjs/hjyny'),
        name: 'hjyny',
        meta: { title: '环境与能源', nav: false, isBreadcrumb: true },
      },
      {
        path: 'hjynyDetail',
        component: _import('modules/xbjs/hjynyDetail'),
        name: 'hjynyDetail',
        meta: { title: '环境与能源', nav: false, isBreadcrumb: true },
      },
      {
        path: 'mzwh',
        component: _import('modules/xbjs/mzwh'),
        name: 'mzwh',
        meta: { title: '民族文化', nav: false, isBreadcrumb: true },
      },
      {
        path: 'mzwhDetail',
        component: _import('modules/xbjs/mzwhDetail'),
        name: 'mzwhDetail',
        meta: { title: '民族文化', nav: false, isBreadcrumb: true },
      },
      {
        path: 'lyysm',
        component: _import('modules/xbjs/lyysm'),
        name: 'lyysm',
        meta: { title: '旅游与商贸', nav: false, isBreadcrumb: true },
      },
      {
        path: 'lyysmDetail',
        component: _import('modules/xbjs/lyysmDetail'),
        name: 'lyysmDetail',
        meta: { title: '旅游与商贸', nav: false, isBreadcrumb: true },
      },
      {
        path: 'jcwhb',
        component: _import('modules/xbjs/jcwhb'),
        name: 'jcwhb',
        meta: { title: '基础文化部', nav: false, isBreadcrumb: true },
      },
      {
        path: 'jcwhbDetail',
        component: _import('modules/xbjs/jcwhbDetail'),
        name: 'jcwhbDetail',
        meta: { title: '基础文化部', nav: false, isBreadcrumb: true },
      }
    ]
  }
]
export default xbjsRoute