<!-- 网上报名 -->
<template>
  <div>
    <div class="flex-column__center result">
      <div class="steps">
        <div class="step olds">基本信息-></div>
        <div class="step active">毕业概况-></div>
        <div class="step">报读专业-></div>
        <div class="step">家庭成员</div>
      </div>
      <div v-if="form.status==1">当前状态：待审核</div>
      <div v-if="form.status==2">当前状态：未通过</div>
      <div v-if="form.status==3">当前状态：待审核</div>
      <div v-if="form.status==4">当前状态：未提交</div>
    </div>

    <el-form
        :inline="true"
        :disabled="state"
        class="baoming clearfix"
        ref="form"
        label-width="80px"
        :model="form"
    >

      <el-col>
        <el-form-item label-width="80px" required label="毕业学校">
          <el-select v-model="form.school" placeholder="的请填写" @change="selectSchool">
            <el-option v-for="(a,i) in schoolList" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="showquwai">
          <el-input v-model="form.schools"></el-input>
        </el-form-item>
        <el-form-item label-width="80px" required label="毕业专业">
          <el-select style="width: 200px;" v-model="form.professional" placeholder="选择毕业专业(大类)">
            <el-option v-for="(a,i) in professionList" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="80px" required label="艺考类型" v-show="yikaod">
          <el-select v-model="form.yikao" placeholder="请选择" @change="selectYikao">
            <el-option v-for="(a,i) in JxArr" :key="i" :label="a" :value="a" ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item required label="毕业时间">
          <el-date-picker
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="form.time"
          ></el-date-picker>
        </el-form-item>
        <el-form-item required label="本人手机">
          <el-input maxlength="11" v-model="form.phone" placeholder="保持电话畅通"></el-input>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item  required label="毕业证书">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess7"
          >
            <img v-if="form.byzs" :src="baseUrl + form.byzs" class="sfz"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color:red">注：请保证毕业证书完全在照片中,且信息清晰可见</p>
        </el-form-item>
      </el-col>

      <el-form-item label-width="80px" required label="高考成绩" v-show="kaocj">
        <el-input v-model="form.score1"></el-input>
      </el-form-item>
      <el-form-item label-width="80px" required label="数学成绩" v-show="kaocj">
        <el-input v-model="form.sxscore"></el-input>
      </el-form-item>
      <el-form-item label-width="80px" required label="艺考成绩" v-show="yikao">
        <el-input v-model="form.zzscore"></el-input>
      </el-form-item>

      <el-col>
        <el-form-item required label="高考成绩" v-show="kaocj">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess5">
            <img v-if="form.gaokao" :src="baseUrl + form.gaokao" class="sfz"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color:red">注：请保证姓名和成绩在照片中,且信息清晰可见</p>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="120px" label="艺考成绩截图" v-show="yikao">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess3"
          >
            <img v-if="form.yikaoimg" :src="baseUrl + form.yikaoimg" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color:red">注：非艺考生不用传</p>
        </el-form-item>
      </el-col>

      <el-form-item label-width="80px" required label="中职总分" v-show="zhicj">
        <el-input v-model="form.score2"></el-input>
      </el-form-item>
      <el-form-item label-width="80px" required label="专业成绩" v-show="zhicj">
        <el-input v-model="form.zzscore"></el-input>
      </el-form-item>

      <el-col>
        <el-form-item label-width="120px" required label="中职成绩截图" v-show="zhicj">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess6">
            <img v-if="form.gaozhi" :src="baseUrl + form.gaozhi" class="sfz"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color:red">注：请保证姓名和成绩在照片中,且信息清晰可见</p>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="80px" required label="获奖等级">
          <el-select v-model="form.prize" placeholder="请选择">
            <el-option v-for="(a,i) in prizeArr" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="100px" label="获奖证书图片">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess4"
          >
            <img v-if="form.prizeimg" :src="baseUrl + form.prizeimg" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color:red">注：无获奖不用传</p>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item required label="所属类型" v-show="zhengming">
          <el-select v-model="form.cate2lx" placeholder="请选择">
            <el-option v-for="(a,i) in cate2lxs" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="145px" label="录取通知书/退学证明" v-show="zhengming">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess1"
          >
            <img v-if="form.zhengming" :src="baseUrl + form.zhengming" class="avatar"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color:red">注：中职应届生不传此项、被高校录取新生传录取通知书、已退学高校生传退学证明</p>
        </el-form-item>
      </el-col>


      <el-col>
        <el-form-item label-width="80px" required label="部队名称" v-show="tyjr">
          <el-input v-model="form.bdmc"></el-input>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item required label="退役证" v-show="tyjr">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess5">
            <img v-if="form.gaokao" :src="baseUrl + form.gaokao" class="sfz"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color:red">注：请保证退役军人证信息完整</p>
        </el-form-item>
      </el-col>

      <el-col>
        <div class="flex-center submit">
          <el-input style="display: none" v-model="form.step"></el-input>
          <el-button type="primary" @click="$router.push('/zsjy/wsbm1?cate_id='+form.cate_id)">上一步</el-button>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </div>
      </el-col>
    </el-form>

    <!-- 提交 -->
    <el-dialog title="保存成功,请继续填写" width="30%" center>
      <div class="flex-center">
        <img src="~images/success.png" alt srcset/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$router.push('/zsjy/wsbm3?cate_id='+form.cate_id)">下一步</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {Session} from "@/utils/storage";
import {baseURL} from "@/config";
import { pcaTextArr } from "element-china-area-data";
import {HTTP_signProfession, HTTP_signSchool} from "../../../http/modules/zsjy";

export default {
  data() {
    return {
      pcaTextArr,
      showquwai: false,
      zhicj: false,
      kaocj: false,
      yikaod: false,
      yikao: false,
      zhengming: false,
      tyjr: false,
      professionList: [],
      schoolList: [],
      state: false,
      uploadData: {
        api_token: Session.get("js_api_token"),
        p: "w",
      },
      prizeArr: ["无", "自治区级优胜奖及以上", "地市级二等奖及以上", "区外校级二等奖及以上"],
      cate2lxs: ["中职应届生", "被高校录取新生", "已退学高校生"],
      JxArr: ["非艺考生","艺考生"],
      arrAll:[],
      province:'',
      city:'',
      county:'',
      baseUrl: baseURL,
      form: {
        cate2lx: "", //第二类型选择
        gaokao: "", //高考成绩照片
        gaozhi: "", //中职成绩照片
        byzs: "", //毕业证书照片
        score1: "", //高考成绩
        score2: "", //中职成绩
        zzscore: "", //专业综合成绩
        sxscore: "", //数学成绩
        school: "", //毕业学校
        bdmc: "", //不对名称
        schools: "", //区外学校
        professional: "", //毕业专业
        time: "", //毕业时间
      },
    };
  },
  mounted() {
    var cate_id=this.$route.query.cate_id;
    this.$api.HTTP_signIndex(cate_id).then((res) => {
      this.state = !res.state;
      this.form = res;
      this.form.step = 2;
      this.form.cate_id=this.$route.query.cate_id;
      if (res.cate_id==1) {//只有中职、普高显示填成绩
        this.educationd(res.education);//学历默认显示高考中职
        this.yikaod=true;
      }else if(res.cate_id==2){//中职应届生、被高校录取新生、已退学高校生
        this.educationd(res.education);//学历默认显示高考中职
        this.zhengming=true;
        this.yikaod=true;
      }else if(res.cate_id==3){//退役军人
        this.tyjr=true;
      }else if(res.cate_id==4){//高校毕业生

      }
      this.selectSchool(res.school);//学校区外默认显示
      this.selectYikao(res.yikao);//艺考默认显示
    });
    this.$api.HTTP_signSchool().then((res) => {
      this.schoolList = res;
    });
    this.$api.HTTP_signProfession().then((res) => {
      this.professionList = res;
    });
  },
  methods: {
    educationd(e) {//学历选择
      if (e == '高中') {
        this.zhicj=false;
        this.kaocj=true;
        this.form.score2 =0;
      }else if(e == '中职'){
        this.zhicj=true;
        this.kaocj=false;
        this.form.score1 =0;
      }
      this.$forceUpdate();
    },
    selectSchool(e) {
      if (e == '区外') {
        this.showquwai=true;
        this.$forceUpdate();   // 强制更新
      }else{
        this.value = e;
        this.form.schools ='';
        this.showquwai=false;
      }
      this.$forceUpdate();
    },
    selectYikao(e) {
      if (e =='艺考生') {
        this.yikao=true;
      }else{
        this.value = e;
        this.yikao=false;
      }
      this.$forceUpdate();
    },
    handleAvatarSuccess1(res) { //类型2，证明
      if(res.status==1){
        this.form.zhengming = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess3(res) { //艺考
      if(res.status==1){
        this.form.yikaoimg = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess4(res) { //特殊奖项
      if(res.status==1){
        this.form.prizeimg = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess5(res) {
      if(res.status==1){
        this.form.gaokao = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess6(res) {
      if(res.status==1){
        this.form.gaozhi = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess7(res) {
      if(res.status==1){
        this.form.byzs = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        // this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    /* 提交 */
    onSubmit() {
      this.$api.HTTP_signSave(this.form).then((res) => {
        //返回res是data
        this.$message.success("保存成功，请继续填写");
        this.$router.replace('/zsjy/wsbm3?cate_id='+this.$route.query.cate_id);
      });
    },
    /* 重置 */
    reset() {
      // this.$refs["form"].resetFields();
    },
  },
};
</script>
<style lang='scss' scoped>
.result {
  padding: 20px;
  border: 1px solid #dcdfe6;
  margin-bottom: 20px;
  position: relative;

  .status {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .steps {
    display: flex;
    justify-content: space-around; //平分空间
    margin: 20px 0;
    background-color: #00ff00;
  }
  .step {
    width: 100px;
    height: 40px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .olds {
    background-color: #ffff00;
  }
  .active {
    background-color: #ff4d51;
  }
}

.dialogImg {
  width: 100px;
  margin-bottom: 15px;
}

.failTxt {
  text-align: center;
  font-size: 20px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.baoming {
  margin: 0 auto;
  width: 950px;

  .title {
    font-size: 16px;
    border-left: 6px solid $base-border-color;
    padding-left: 10px;
    margin-bottom: 15px;
  }

  .resumeItem {
    position: relative;

    .icon_rudce {
      width: 20px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .addResume {
    text-align: center;
    margin-bottom: 22px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 6px;
    }
  }

  .submit {
    padding-bottom: 20px;
  }
}

/deep/ .avatar-uploader {
  .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: $base-border-color;
    }
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 150px;
    height: 206px;
    display: block;
  }

  .sfz {
    width: 300px;
    height: 180px;
    display: block;
  }
}

.commitment {
  width: 1200px;
  margin: 0 auto;

  &-title {
    text-align: center;
    font-size: 30px;
    line-height: 60px;
  }
}
</style>