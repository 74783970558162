const _import = require('../config/import')
const twgzRoute = [
    {
        path: '/twgz',
        component: _import('modules/twgz/index'),
        name: 'twgz',
        meta: { title: '团委工作',nav:true,isBreadcrumb:true},
        children:[]
    },
    {
        path: '/twgzDetail',
        component: _import('modules/twgz/detail'),
        name: 'twgzDetail',
        meta: { title: '团委工作',nav:false,isBreadcrumb:true},
        children:[]
    },
]
export default twgzRoute