const _import = require('../config/import')
const xbjsRoute = [
    {
        path: '/xsgl',
        component: _import('modules/xsgl/index'),
        name: 'xsgl',
        meta: { title: '学生管理', nav: true },
        redirect:{name:'xszz'},
        children: [
            {
                path: 'xszz',
                component: _import('modules/zsjy/xszz'),
                name: 'xszz',
                meta: { title: '学生资助', nav: true, isBreadcrumb: true },
            }, 
            {
                path: 'xszzDetail',
                component: _import('modules/zsjy/xszzDetail'),
                name: 'xszzDetail',
                meta: { title: '学生资助', nav: false, isBreadcrumb: true },
            },
            {
                path: 'xywh',
                component: _import('modules/xsgl/xywh'),
                name: 'xywh',
                meta: { title: '校园文化', nav: true, isBreadcrumb: true },
            },
            {
                path: 'xywhDetail',
                component: _import('modules/xsgl/xywhDetail'),
                name: 'xywhDetail',
                meta: { title: '校园文化', nav: false, isBreadcrumb: true },
            },
            {
                path: 'szjy',
                component: _import('modules/xsgl/szjy'),
                name: 'szjy',
                meta: { title: '思政教育', nav: true, isBreadcrumb: true },
            },
            {
                path: 'szjyDetail',
                component: _import('modules/xsgl/szjyDetail'),
                name: 'szjyDetail',
                meta: { title: '思政教育', nav: false, isBreadcrumb: true },
            },
            {
                path: 'gfjy',
                component: _import('modules/xsgl/gfjy'),
                name: 'gfjy',
                meta: { title: '国防教育', nav: true, isBreadcrumb: true },
            },
            {
                path: 'gfjyDetail',
                component: _import('modules/xsgl/gfjyDetail'),
                name: 'gfjyDetail',
                meta: { title: '国防教育', nav: false, isBreadcrumb: true },
            },
            {
                path: 'xljkjy',
                component: _import('modules/xsgl/xljkjy'),
                name: 'xljkjy',
                meta: { title: '心理健康教育', nav: true, isBreadcrumb: true },
            },
            {
                path: 'xljkjyDetail',
                component: _import('modules/xsgl/xljkjyDetail'),
                name: 'xljkjyDetail',
                meta: { title: '心理健康教育', nav: false, isBreadcrumb: true },
            },
        ]
    },

]
export default xbjsRoute