import req from '../api.js'
/* 学院简介 */
export const HTTP_AboutIndex = () => req('/api/about/index')
/* 现任领导 */
export const HTTP_AboutLeader = () => req('/api/about/leader')
/* 组织机构 */
export const HTTP_AboutOrganization = () => req('/api/about/organization')
/* 校徽校训 */
export const HTTP_AboutXhxx = () => req('/api/about/xhxx')
/* 校园风貌 */
export const HTTP_AboutXyfm = (page, size) => req('/api/about/xyfm', { page, size })
/* 校园风貌详情 */
export const HTTP_XyfmInfo = (id) => req('/api/about/xyfminfo', { id })
/* 政策文献 */
export const HTTP_AboutZcwx = (page, size) => req('/api/about/zcwx', { page, size })
/* 政策文献详情 */
export const HTTP_AboutZcwxInfo = (id) => req('/api/about/zcwxinfo', { id })
/* 办学理念  */
export const HTTP_AboutPhilosophy = () => req('/api/about/philosophy')
/* 交通指引  */
export const HTTP_AboutRoute = () => req('/api/about/route')