/*
 * @Descripttion: 导入所有接口
 * @version: 0.0.1
 * @Author: liyang.guan
 * @Date: 2020-10-29 16:47:18
 * @LastEditors: liyang.guan
 * @LastEditTime: 2020-10-30 09:58:01
 */
import apiList from './modules/index'

const install = Vue => {
  if (install.installed) return
  install.installed = true
  Object.defineProperties(Vue.prototype, {
    // 此处挂载在 Vue 原型的 $api 对象上
    $api: {
      get() {
        return apiList
      }
    }
  })
}
export default install
