<!-- 网上报名 -->
<template>
  <div>
    <div class="flex-column__center result">
      <div class="steps">
        <div class="step olds">基本信息-></div>
        <div class="step olds">毕业概况-></div>
        <div class="step active">报读专业-></div>
        <div class="step">家庭成员</div>
      </div>
      <div v-if="form.status==1">当前状态：待审核</div>
      <div v-if="form.status==2">当前状态：未通过</div>
      <div v-if="form.status==3">当前状态：待审核</div>
      <div v-if="form.status==4">当前状态：未提交</div>
    </div>

    <el-form
        :inline="true"
        :disabled="state"
        class="baoming clearfix"
        ref="form"
        label-width="80px"
        :model="form"
    >
      <el-col>
        <p class="title">报读专业:</p>
        <el-form-item required label-width="80px" label="第一志愿">
          <el-select style="width: 200px;" v-model="form.one" placeholder="选择第一志愿">
            <el-option v-for="(a,i) in majorList" :key="i" :label="a.titles" @click.native="handleClick(a)" :value="a.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" label="第二志愿">
          <el-select style="width: 200px;" v-model="form.two" placeholder="选择第二志愿">
            <el-option v-for="(a,i) in majorList" :key="i" :label="a.titles" @click.native="handleClick(a)" :value="a.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" label="第三志愿">
          <el-select style="width: 200px;" v-model="form.three" placeholder="选择第三志愿">
            <el-option v-for="(a,i) in majorList" :key="i" :label="a.titles" @click.native="handleClick(a)" :value="a.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" label="第四志愿" v-if="form.cate_id!=4">
          <el-select style="width: 200px;" v-model="form.four" placeholder="选择第四志愿">
            <el-option v-for="(a,i) in majorList" :key="i" :label="a.titles" @click.native="handleClick(a)" :value="a.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="80px" label="第五志愿" v-if="form.cate_id!=4">
          <el-select style="width: 200px;" v-model="form.five" placeholder="选择第五志愿">
            <el-option v-for="(a,i) in majorList" :key="i" :label="a.titles" @click.native="handleClick(a)" :value="a.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item required label-width="110px" label="是否服从调剂">
          <el-radio-group v-model="form.adjust">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item required label="邮寄地址">
          <el-cascader style="width: 350px;" size="large" :options="pcaTextArr" v-model="form.addressd"></el-cascader>
          <el-input style="width: 250px;padding-left:20px;" v-model="form.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item required label="邮寄电话">
          <el-input maxlength="11" v-model="form.mobile" placeholder="需和本人电话号码不一样"></el-input>
        </el-form-item>
      </el-col>

      <el-col>
        <div class="flex-center submit">
          <el-input style="display: none" v-model="form.step"></el-input>
          <el-button type="primary" @click="$router.push('/zsjy/wsbm2?cate_id='+form.cate_id)">上一步</el-button>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </div>
      </el-col>
    </el-form>

    <!-- 提交 -->
    <el-dialog title="保存成功,请继续填写" width="30%" center>
      <div class="flex-center">
        <img src="~images/success.png" alt srcset/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$router.push('/zsjy/wsbm4?cate_id='+form.cate_id)">下一步</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {Session} from "@/utils/storage";
import {baseURL} from "@/config";
import { pcaTextArr } from "element-china-area-data";
import {HTTP_signSchool} from "../../../http/modules/zsjy";

export default {
  data() {
    return {
      pcaTextArr,
      showquwai: false,
      zhicj: false,
      kaocj: false,
      commitment: "",
      majorList: [],
      schoolList: [],
      state: false,
      uploadData: {
        api_token: Session.get("js_api_token"),
        p: "w",
      },
      placeArr: ["西藏技师学院考点", "那曲考点", "阿里考点", "昌都考点"],
      arrAll:[],
      province:'',
      city:'',
      county:'',
      baseUrl: baseURL,
      form: {
        one: "", //报读专业第一志愿
        two: "", //报读专业第二志愿
        three: "", //报读专业第三志愿
        four: "", //报读专业第四志愿
        five: "", //报读专业第五志愿
        adjust: "是", //是否服从调剂
        addressd: [], //邮寄地址数组
        address: "", //邮寄地址及邮政编码
        mobile: "", //联系电话
        status: 0,
        notice: "",
        reason: "",
      },
    };
  },
  mounted() {
    var cate_id=this.$route.query.cate_id;
    this.$api.HTTP_signIndex(cate_id).then((res) => {
      this.state = !res.state;
      this.form = res;
      this.form.step =3;
      this.form.cate_id=this.$route.query.cate_id;
    });
    this.$api.HTTP_signMajor().then((res) => {
      this.majorList = res;
    });
  },
  methods: {
    handleClick(item) {
      this.$message.warning("该专业要求："+item.needs);
    },
    /* 提交 */
    onSubmit() {
      this.$api.HTTP_signSave(this.form).then((res) => {
        //返回res是data
        this.$message.success("保存成功，请继续填写");
        this.$router.replace('/zsjy/wsbm4?cate_id='+this.$route.query.cate_id);
      });
    },
    /* 重置 */
    reset() {
      // this.$refs["form"].resetFields();
    },
  },
};
</script>
<style lang='scss' scoped>
.result {
  padding: 20px;
  border: 1px solid #dcdfe6;
  margin-bottom: 20px;
  position: relative;

  .status {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .statusInfo {
    font-size: 20px;
    margin-bottom: 20px;

    span {
      font-size: 20px;
    }
  }
  .el-message {
    margin-top: auto;
  }
  .steps {
    display: flex;
    justify-content: space-around; //平分空间
    margin: 20px 0;
    background-color: #00ff00;
  }
  .step {
    width: 100px;
    height: 40px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .olds {
    background-color: #ffff00;
  }
  .active {
    background-color: #ff4d51;
  }
}

.dialogImg {
  width: 100px;
  margin-bottom: 15px;
}

.failTxt {
  text-align: center;
  font-size: 20px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.baoming {
  margin: 0 auto;
  width: 950px;

  .title {
    font-size: 16px;
    border-left: 6px solid $base-border-color;
    padding-left: 10px;
    margin-bottom: 15px;
  }

  .resumeItem {
    position: relative;

    .icon_rudce {
      width: 20px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .addResume {
    text-align: center;
    margin-bottom: 22px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 6px;
    }
  }

  .submit {
    padding-bottom: 20px;
  }
}

/deep/ .avatar-uploader {
  .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: $base-border-color;
    }
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 150px;
    height: 206px;
    display: block;
  }

  .sfz {
    width: 300px;
    height: 180px;
    display: block;
  }
}

.commitment {
  width: 1200px;
  margin: 0 auto;

  &-title {
    text-align: center;
    font-size: 30px;
    line-height: 60px;
  }
}
</style>