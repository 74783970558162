<!-- 网上报名 -->
<template>
  <div>
    <div class="flex-column__center result">
      <div class="steps">
        <div class="step olds">基本信息-></div>
        <div class="step olds">毕业概况-></div>
        <div class="step olds">报读专业-></div>
        <div class="step active">家庭成员</div>
      </div>
      <div v-if="form.status==1">当前状态：待审核</div>
      <div v-if="form.status==2">当前状态：未通过</div>
      <div v-if="form.status==3">当前状态：待审核</div>
      <div v-if="form.status==4">当前状态：未提交</div>
    </div>

    <el-form
        :inline="true"
        :disabled="state"
        class="baoming clearfix"
        ref="form"
        label-width="80px"
        :model="form"
    >

      <el-col>
        <p class="title">个人学习简历</p>
        <div class="resumeItem" v-for="(a,i) in form.resume" :key="i">
          <el-form-item required label="起止时间">
            <el-date-picker
                type="daterange"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                v-model="a.rtimes"
            ></el-date-picker>
          </el-form-item>
          <el-form-item required label="学校名称">
            <el-input style="width:150px" v-model="a.rschool"></el-input>
          </el-form-item>
          <el-form-item required label="证明人">
            <el-input style="width:150px" v-model="a.certifier"></el-input>
          </el-form-item>
          <img
              class="icon_rudce"
              @click="reduceResumeItem(i)"
              v-if="i>0"
              src="~images/icon_rudce.png"
              alt
          />
        </div>
        <div class="addResume flex-center" @click="addResume">
          <img src="~images/icon_add.png" alt/> 添加学习简历
        </div>
      </el-col>
      <el-col>
        <el-form-item required label="户主姓名">
          <el-input v-model="form.household"></el-input>
        </el-form-item>
        <el-form-item required label-width="120px" label="与考生的关系">
          <el-select v-model="form.relation" placeholder="选择与考生的关系">
            <el-option v-for="(a,i) in relationArr" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col>
        <p class="title">家庭成员信息</p>
        <div v-for="(a,i) in form.members" :key="i" class="resumeItem">
          <el-form-item required label="称谓">
            <el-select style="width:150px" v-model="a.appellation" placeholder="称谓">
              <el-option v-for="(a,i) in gxArr" :key="i" :label="a" :value="a"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="60px" required label="姓名">
            <el-input style="width:150px" v-model="a.mname"></el-input>
          </el-form-item>
          <el-form-item required label="工作单位">
            <el-input v-model="a.work"></el-input>
          </el-form-item>
          <el-form-item required label="政治面貌">
            <el-select style="width:150px" v-model="a.mpolitical" placeholder="选择政治面貌">
              <el-option v-for="(a,i) in politicalArr" :key="i" :label="a" :value="a"></el-option>
            </el-select>
          </el-form-item>
          <img
              class="icon_rudce"
              @click="reduceMembersItem(i)"
              v-if="i>0"
              src="~images/icon_rudce.png"
              alt
          />
        </div>
        <div class="addResume flex-center" @click="addMembers">
          <img src="~images/icon_add.png" alt/> 添加家庭成员
        </div>
      </el-col>
      <el-col>
        <div class="flex-center submit">
          <el-input style="display: none" v-model="form.step"></el-input>
          <el-button type="primary" @click="$router.push('/zsjy/wsbm3?cate_id='+form.cate_id)">上一步</el-button>
          <el-button type="primary" @click="onSubmit">提交报名</el-button>
        </div>
      </el-col>
    </el-form>

    <!-- 提交 -->
    <el-dialog title="提交成功" width="30%" center>
      <div class="flex-center">
        <img src="~images/success.png" alt srcset/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$router.push('/zsjy/wsbm')">返回首页</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {Session} from "@/utils/storage";
import {baseURL} from "@/config";
import { pcaTextArr } from "element-china-area-data";
import {HTTP_signSchool} from "../../../http/modules/zsjy";

export default {
  data() {
    return {
      state: false,
      uploadData: {
        api_token: Session.get("js_api_token"),
        p: "w",
      },
      arrAll:[],
      politicalArr: ["共青团员", "群众", "党员"],
      gxArr: ["父亲", "母亲", "爷爷", "奶奶", "哥哥", "姐姐", "其他"],
      relationArr: ["父子", "母子","孙子","兄弟","姐妹", "其他"],
      province:'',
      city:'',
      county:'',
      baseUrl: baseURL,
      form: {
        resume: [
          {
            rtime: "", //个人学习简历起止时间
            rschool: "", //个人学习简历学校名称
            certifier: "", //个人学习简历证明人
          },
        ], //个人学习简历
        household: "", //户主姓名
        relation: "", //与考生的关系
        members: [
          {
            appellation: "", //家庭成员称谓
            mname: "", //家庭成员姓名
            work: "", //家庭成员工作单位
            mpolitical: "", //家庭成员政治面貌
          },
        ], //家庭成员信息
        status: 0,
        notice: "",
        reason: "",
      },
    };
  },
  mounted() {
    var cate_id=this.$route.query.cate_id;
    this.$api.HTTP_signIndex(cate_id).then((res) => {
      if (res.resume) {
        res.resume.forEach((o) => {
          o.rtime = o.rtime.split("至");
        });
      }
      this.state = !res.state;
      this.form = res;
      this.form.step =4;
      this.form.cate_id=this.$route.query.cate_id;
    });

  },
  methods: {
    addResume() {
      let obj = {
        rtimes: "", //个人学习简历起止时间
        rschool: "", //个人学习简历学校名称
        certifier: "", //个人学习简历证明人
      };
      this.form.resume.push(obj);
    },
    reduceResumeItem(i) {
      this.form.resume.splice(i, 1);
    },
    addMembers() {
      let obj = {
        appellation: "", //家庭成员称谓
        mname: "", //家庭成员姓名
        work: "", //家庭成员工作单位
        mpolitical: "", //家庭成员政治面
      };
      this.form.members.push(obj);
    },
    reduceMembersItem(i) {
      this.form.members.splice(i, 1);
    },
    handleAvatarSuccess(res) {
      if(res.status==1){
        this.form.avatar = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess1(res) {
      if(res.status==1){
        this.form.medical = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess2(res) {
      if(res.status==1){
        this.form.medical2 = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess3(res) {
      if(res.status==1){
        this.form.sfzz = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess4(res) {
      if(res.status==1){
        this.form.sfzf = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess5(res) {
      if(res.status==1){
        this.form.gaokao = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess6(res) {
      if(res.status==1){
        this.form.gaozhi = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess7(res) {
      if(res.status==1){
        this.form.byzs = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        // this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    /* 提交 */
    onSubmit() {
      if (this.form.resume) {
        this.form.resume.forEach((o) => {
          if (o.rtimes) {
            o.rtime = o.rtimes.join("至");
          }
        });
      }
      this.$api.HTTP_signSave(this.form).then((res) => {
        //返回res是data
        this.$message.success("提交成功，请等待审核");
        this.$router.replace('/zsjy/wsbm');
      });
    },
    /* 重置 */
    reset() {
      // this.$refs["form"].resetFields();
    },
  },
};
</script>
<style lang='scss' scoped>
.result {
  padding: 20px;
  border: 1px solid #dcdfe6;
  margin-bottom: 20px;
  position: relative;

  .status {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .statusInfo {
    font-size: 20px;
    margin-bottom: 20px;

    span {
      font-size: 20px;
    }
  }

  .steps {
    display: flex;
    justify-content: space-around; //平分空间
    margin: 20px 0;
    background-color: #00ff00;
  }
  .step {
    width: 100px;
    height: 40px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .olds {
    background-color: #ffff00;
  }
  .active {
    background-color: #ff4d51;
  }
}

.dialogImg {
  width: 100px;
  margin-bottom: 15px;
}

.failTxt {
  text-align: center;
  font-size: 20px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.baoming {
  margin: 0 auto;
  width: 950px;

  .title {
    font-size: 16px;
    border-left: 6px solid $base-border-color;
    padding-left: 10px;
    margin-bottom: 15px;
  }

  .resumeItem {
    position: relative;

    .icon_rudce {
      width: 20px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .addResume {
    text-align: center;
    margin-bottom: 22px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 6px;
    }
  }

  .submit {
    padding-bottom: 20px;
  }
}

/deep/ .avatar-uploader {
  .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: $base-border-color;
    }
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 150px;
    height: 206px;
    display: block;
  }

  .sfz {
    width: 300px;
    height: 180px;
    display: block;
  }
}

.commitment {
  width: 1200px;
  margin: 0 auto;

  &-title {
    text-align: center;
    font-size: 30px;
    line-height: 60px;
  }
}
</style>