<!-- 联系方式 -->
<template>
  <div>联系方式</div>
</template>

<script>
export default {
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  mounted(){},

  methods: {}
}

</script>
<style lang='scss' scoped>
</style>