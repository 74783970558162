const _import = require('../config/import')
const jxyjRoute = [
  {
    path: '/jxjy',
    component: _import('modules/jxjy/index'),
    name: 'jxjy',
    redirect: { name: 'jxyj' },
    meta: { title: '教学教研', nav: true },
    children: [
      {
        path: 'jxyj',
        component: _import('modules/jxjy/jxyj'),
        name: 'jxyj',
        meta: { title: '教学研究', nav: true, isBreadcrumb: true },
      },
      {
        path: 'jxyjDetail',
        component: _import('modules/jxjy/jxyjDetail'),
        name: 'jxyjDetail',
        meta: { title: '教学研究', nav: false, isBreadcrumb: true },
      },
      {
        path: 'zyjs',
        component: _import('modules/jxjy/zyjs'),
        name: 'zyjs',
        meta: { title: '专业建设', nav: true, isBreadcrumb: true },
      },
      {
        path: 'zyjsDetail',
        component: _import('modules/jxjy/zyjsDetail'),
        name: 'zyjsDetail',
        meta: { title: '专业建设', nav: false, isBreadcrumb: true },
      },
      {
        path: 'jxcg',
        component: _import('modules/jxjy/jxcg'),
        name: 'jxcg',
        meta: { title: '教学成果', nav: true, isBreadcrumb: true },
      },
      {
        path: 'jxcgDetail',
        component: _import('modules/jxjy/jxcgDetail'),
        name: 'jxcgDetail',
        meta: { title: '教学成果', nav: false, isBreadcrumb: true },
      },
      {
        path: 'jsfc',
        component: _import('modules/jxjy/jsfc'),
        name: 'jsfc',
        meta: { title: '教师风采', nav: true, isBreadcrumb: true },
      },
      {
        path: 'jsfcDetail',
        component: _import('modules/jxjy/jsfcDetail'),
        name: 'jsfcDetail',
        meta: { title: '教师风采', nav: false, isBreadcrumb: true },
      },
    ]
  }
]
export default jxyjRoute