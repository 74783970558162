<!-- 网上报名 -->
<template>
  <div>
    <div class="flex-column__center result">
      <div class="steps">
        <div class="step active">基本信息-></div>
        <div class="step">毕业概况-></div>
        <div class="step">报读专业-></div>
        <div class="step">家庭成员</div>
      </div>
      <div v-if="form.status==1">当前状态：待审核</div>
      <div v-if="form.status==2">当前状态：未通过</div>
      <div v-if="form.status==3">当前状态：待审核</div>
      <div v-if="form.status==4">当前状态：未提交</div>
    </div>

    <el-form
        :inline="true"
        :disabled="state"
        class="baoming clearfix"
        ref="form"
        label-width="80px"
        :model="form"
    >
      <el-col>
        <el-form-item required label-width="80px" label="姓名">
          <el-input v-model="form.name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item required label-width="80px" label="性别">
          <el-radio-group style="width:202px" v-model="form.sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item required label-width="80px" label="民族">
          <el-select v-model="form.national" placeholder="选择民族">
            <el-option v-for="(a,i) in nationalArr" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item required label-width="95px" label="政治面貌">
          <el-select style="width:200px" v-model="form.political" placeholder="选择政治面貌">
            <el-option v-for="(a,i) in politicalArr" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item required label-width="75px" label="学历">

          <el-select style="width:200px" v-model="form.education" placeholder="选择学历">
            <el-option v-for="(a,i) in educationArr" :key="i" :label="a" :value="a"></el-option>
          </el-select>

        </el-form-item>

        <el-form-item required label-width="90px" label="健康状况">
          <el-select style="width:200px" v-model="form.health" placeholder="选择健康状况">
            <el-option v-for="(a,i) in healthArr" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item label-width="80px" required label="特长">
          <el-input
              style="width:786px"
              rows="4"
              maxlength="255"
              show-word-limit
              type="textarea"
              v-model="form.specialty"
          ></el-input>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="120px" required label="一寸免冠照片">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
          >
            <img v-if="form.avatar" :src="baseUrl + form.avatar" class="avatar"/>
            <img v-else src="~images/avatar.png" class="avatar"/>
<!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
          </el-upload>
          <p style="color:red"><a target="_blank" href="/uploadfiles/zhaopian.rar">下载照片处理工具,</a>注：像素295*413，格式可为jpg、png、jpeg、gif,背景可选用浅灰色、淡蓝色或白色,分辨率不低于300dpi，小于500k
          </p>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="120px" required label="体检报告">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess1"
          >
            <img v-if="form.medical" :src="baseUrl + form.medical" class="avatar"/>
            <img v-else src="~images/tijian.jpg" class="avatar"/>
<!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
          </el-upload>
          <p style="color:red">注：2024年毕业生可用高考体检表，6月20日后下载<a target="_blank" href="/uploadfiles/tijian.docx">体检表</a>在三级甲等医院进行体检,小于2M
          </p>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item label-width="120px" required label="身份证正面">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess3"
          >
            <img v-if="form.sfzz" :src="baseUrl + form.sfzz" class="sfz"/>
            <img v-else src="~images/sfzz.png" class="avatar"/>
            <!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
          </el-upload>
          <p style="color:red">注：请横拍保证身份证完全在照片中,且信息清晰可见,小于2M</p>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item label-width="120px" required label="身份证反面">
          <el-upload
              class="avatar-uploader"
              action="/api/users/uploads"
              :data="uploadData"
              accept="image/*"
              name="file"
              :show-file-list="false"
              :on-success="handleAvatarSuccess4"
          >
            <img v-if="form.sfzf" :src="baseUrl + form.sfzf" class="sfz"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <p style="color:red">注：横拍,小于2M</p>
        </el-form-item>
      </el-col>
      <el-col>
        <el-form-item required label-width="80px" label="身高(cm)">
          <el-input v-model="form.height" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item maxlength="18" required label-width="80px" label="身份证号">
          <el-input v-model="form.card"></el-input>
        </el-form-item>
        <el-form-item label-width="80px" required label="户籍性质">
          <el-select v-model="form.nature" placeholder="选择户籍性质">
            <el-option v-for="(a,i) in natureArr" :key="i" :label="a" :value="a"></el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col>
        <el-form-item required label-width="100px" label="户籍所在地">
          <el-cascader style="width: 350px;" size="large" :options="pcaTextArr" v-model="form.censusd"></el-cascader>
          <el-input style="width: 250px;padding-left:20px;" v-model="form.census" placeholder="请输入详细地址"></el-input>
        </el-form-item>
      </el-col>
      <el-col>
        <div class="flex-center submit">
          <el-input style="display: none" v-model="form.step"></el-input>
          <el-button type="primary" @click="$router.push('/zsjy/wsbm')">上一步</el-button>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </div>
      </el-col>
    </el-form>

    <!-- 提交 -->
    <el-dialog title="保存成功,请继续填写" width="30%" center>
      <div class="flex-center">
        <img src="~images/success.png" alt srcset/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$router.push('/zsjy/wsbm2?cate_id='+form.cate_id)">下一步</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {Session} from "@/utils/storage";
import {baseURL} from "@/config";
import {pcaTextArr} from "element-china-area-data";

export default {
  data() {
    return {
      pcaTextArr,
      showquwai: false,
      zhicj: false,
      kaocj: false,
      majorList: [],
      schoolList: [],
      state: false,
      uploadData: {
        api_token: Session.get("js_api_token"),
        p: "w",
      },
      nationalArr: [
        "藏族",
        "汉族",
        "壮族",
        "满族",
        "回族",
        "苗族",
        "维吾尔族",
        "土家族",
        "彝族",
        "蒙古族",
        "布依族",
        "侗族",
        "瑶族",
        "朝鲜族",
        "白族",
        "哈尼族",
        "哈萨克族",
        "黎族",
        "傣族",
        "畲族",
        "傈僳族",
        "仡佬族",
        "东乡族",
        "高山族",
        "拉祜族",
        "水族",
        "佤族",
        "纳西族",
        "羌族",
        "土族",
        "仫佬族",
        "锡伯族",
        "柯尔克孜族",
        "达斡尔族",
        "景颇族",
        "毛南族",
        "撒拉族",
        "塔吉克族",
        "阿昌族",
        "普米族",
        "鄂温克族",
        "怒族",
        "京族",
        "基诺族",
        "德昂族",
        "保安族",
        "俄罗斯族",
        "裕固族",
        "乌兹别克族",
        "门巴族",
        "鄂伦春族",
        "独龙族",
        "塔塔尔族",
        "赫哲族",
        "珞巴族",
        "布朗族",
      ],
      politicalArr: ["共青团员", "群众", "党员"],
      educationArr: [[], ["高中", "中职"], ["高中", "中职"], ["高中", "中职", "高职/大专", "本科"], ["高职/大专", "本科"]][this.$route.query.cate_id],
      healthArr: ["良好", "一般", "较差"],
      natureArr: ["农村", "城镇"],
      relationArr: ["父子", "母子", "其他"],
      placeArr: ["西藏技师学院考点", "那曲考点", "阿里考点", "昌都考点"],
      arrAll: [],
      province: '',
      city: '',
      county: '',
      baseUrl: baseURL,
      form: {
        name: "", //x姓名
        sex: "男", //性别
        national: "", //民族
        avatar: "", // 一寸免冠照片
        height: "", // 一寸免冠照片
        medical: "", //体检报告照片
        medical2: "", //中职生参加技能竞赛的获奖情况及证书上传照片
        sfzz: "", //身份整照片
        sfzf: "", //身份反照片
        gaokao: "", //高考成绩照片
        gaozhi: "", //中职成绩照片
        byzs: "", //毕业证书照片
        score1: "", //高考成绩
        score2: "", //中职成绩
        political: "", //政治面貌
        specialty: "", //特长
        health: "", //健康状况
        education: "", //学历
        card: "", //身份证号码
        censusd: [], //户籍所在地数组
        census: "", //户籍所在地
        nature: "", //户籍性质
        school: "", //毕业学校
        schools: "", //区外学校
        professional: "", //毕业专业
        time: "", //毕业时间
        phone: "", //联系电话
        addressd: [], //邮寄地址数组
        address: "", //邮寄地址及邮政编码
        mobile: "", //联系电话
        place: "", //考试地点
        resume: [
          {
            rtime: "", //个人学习简历起止时间
            rschool: "", //个人学习简历学校名称
            certifier: "", //个人学习简历证明人
          },
        ], //个人学习简历
        household: "", //户主姓名
        relation: "", //与考生的关系
        members: [
          {
            appellation: "", //家庭成员称谓
            mname: "", //家庭成员姓名
            work: "", //家庭成员工作单位
            mpolitical: "", //家庭成员政治面貌
          },
        ], //家庭成员信息
        status: 0,
        notice: "",
        reason: "",
      },
    };
  },
  mounted() {
    var cate_id=this.$route.query.cate_id;
    this.$api.HTTP_signIndex(cate_id).then((res) => {
      this.state = !res.state;
      this.form = res;
      this.form.step = 1;
      this.form.cate_id=this.$route.query.cate_id;
    });
  },
  methods: {
    handleAvatarSuccess(res) {
      if (res.status == 1) {
        this.form.avatar = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess1(res) {
      if (res.status == 1) {
        this.form.medical = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },

    handleAvatarSuccess3(res) {
      if (res.status == 1) {
        this.form.sfzz = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess4(res) {
      if (res.status == 1) {
        this.form.sfzf = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        // this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    /* 提交 */
    onSubmit() {
      this.$api.HTTP_signSave(this.form).then((res) => {
        //返回res是data
        this.$message.success("保存成功，请继续填写");
        this.$router.replace('/zsjy/wsbm2?cate_id='+this.$route.query.cate_id);
      });
    },
    /* 重置 */
    reset() {
      // this.$refs["form"].resetFields();
    },
  },
};
</script>
<style lang='scss' scoped>
.result {
  padding: 20px;
  border: 1px solid #dcdfe6;
  margin-bottom: 20px;
  position: relative;

  .steps {
    display: flex;
    justify-content: space-around; //平分空间
    margin: 20px 0;
    background-color: #00ff00;
  }

  .step {
    width: 100px;
    height: 40px;
    border-collapse: collapse;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background-color: #ffff00;
  }

  .active {
    background-color: #ff4d51;
  }
}

.dialogImg {
  width: 100px;
  margin-bottom: 15px;
}

.failTxt {
  text-align: center;
  font-size: 20px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.baoming {
  margin: 0 auto;
  width: 950px;

  .title {
    font-size: 16px;
    border-left: 6px solid $base-border-color;
    padding-left: 10px;
    margin-bottom: 15px;
  }

  .resumeItem {
    position: relative;

    .icon_rudce {
      width: 20px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .addResume {
    text-align: center;
    margin-bottom: 22px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 6px;
    }
  }

  .submit {
    padding-bottom: 20px;
  }
}

/deep/ .avatar-uploader {
  .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: $base-border-color;
    }
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 150px;
    height: 206px;
    display: block;
  }

  .sfz {
    width: 300px;
    height: 180px;
    display: block;
  }
}

</style>