const _import = require('../config/import')
const xygkRoute = [
    {
        path: '/xygk',
        component: _import('modules/xygk/index'),
        name: 'xygk',
        redirect: { name: 'xyjj' },
        meta: { title: '学院概况', nav: true },
        children: [
            {
                path: 'xyjj',
                component: _import('modules/xygk/xyjj'),
                name: 'xyjj',
                meta: { title: '学院简介', nav: true, isBreadcrumb: true },
            },
            {
                path: 'xrld',
                component: _import('modules/xygk/xrld'),
                name: 'xrld',
                meta: { title: '现任领导', nav: true, isBreadcrumb: true },
            },
            {
                path: 'zzjg',
                component: _import('modules/xygk/zzjg'),
                name: 'zzjg',
                meta: { title: '组织机构', nav: true, isBreadcrumb: true },
            },
            {
                path: 'bxln',
                component: _import('modules/xygk/bxln'),
                name: 'bxln',
                meta: { title: '办学理念', nav: true, isBreadcrumb: true },
            },
            {
                path: 'zcwx',
                component: _import('modules/xygk/zcwx'),
                name: 'zcwx',
                meta: { title: '政策文献', nav: true, isBreadcrumb: true },
            },
            {
                path: 'zcwxDetail',
                component: _import('modules/xygk/zcwxDetail'),
                name: 'zcwxDetail',
                meta: { title: '政策文献', nav: false, isBreadcrumb: true },
            },
            {
                path: 'xhxx',
                component: _import('modules/xygk/xhxx'),
                name: 'xhxx',
                meta: { title: '校徽校训', nav: true, isBreadcrumb: true },
            },
            {
                path: 'xyfm',
                component: _import('modules/xygk/xyfm'),
                name: 'xyfm',
                meta: { title: '校园风貌', nav: true, isBreadcrumb: true },
            },
            {
                path: 'xyfmDetail',
                component: _import('modules/xygk/xyfmDetail'),
                name: 'xyfmDetail',
                meta: { title: '校园风貌', nav: false, isBreadcrumb: true },
            },
            {
                path: 'jtzy',
                component: _import('modules/xygk/jtzy'),
                name: 'jtzy',
                meta: { title: '交通指引', nav: true, isBreadcrumb: true },
            }
        ]
    },
]
export default xygkRoute