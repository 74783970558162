<!-- 网上报名 -->
<template>
  <div>
    <div class="commitment">
      <div class="commitment-title">{{ commitment.title }}</div>
      <div v-html="commitment.content"></div>
    </div>

    <div style="margin: 18px;font-size:18px;text-align: center">
      <font color="#dc143c">{{ notice }}</font>
    </div>
    <div class="flex-column__center result">
      <div v-if="statusd" class="statusInfo">{{ statusd }}</div>
      <div style="width: auto"><button class="button anniu2">退役军人</button><button v-if="state1==1" class="button anniu1"  @click="goRoute(3)">报名入口</button></div>
      <div style="width: auto"><button class="button anniu2">高校毕业生</button><button v-if="state1==1" class="button anniu1"  @click="goRoute(4)">报名入口</button></div>
      <div style="width: auto"><button class="button anniu2">普高生、中职往届生</button><button v-if="state1==1" class="button anniu1"  @click="goRoute(1)">报名入口</button></div>
      <div style="width: auto"><button class="button anniu2">中职应届生、被高校录取新生、已退学高校生</button><button v-if="state2==1" class="button anniu1"  @click="goRoute(2)">报名入口</button></div>
    </div>
  </div>
</template>

<script>
import {Session} from "@/utils/storage";
import {baseURL} from "@/config";

export default {
  data() {
    return {
      commitment: "",
      notice: "",
      statusd: "",
      state1: "",
      state2: "",
      baseUrl: baseURL,
    };
  },
  mounted() {
    this.$api.HTTP_signWsbm().then((res) => {
      this.commitment = res.signcommit;
      this.notice = res.notice;
      this.statusd = res.statusd;
      this.state1 = res.state1;
      this.state2 = res.state2;

    });
  },
  methods: {
    goRoute(cate_id) {
      this.$router.push({
        name: "wsbm1",
        query: { cate_id: cate_id },
      });
    },
  },

};
</script>
<style lang='scss' scoped>
.result {
  padding: 20px;
  border: 1px solid #dcdfe6;
  margin-bottom: 20px;
  position: relative;

  .status {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .button {
    width: auto;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid #ccc;

    cursor: pointer;
    transition: background-color 0.3s;
  }

  .button:hover {
    background-color: #1042e7;
  }

  .active {
    background-color: #1042e7;
  }

  .anniu1 {
    font-size: 26px;
    margin-bottom: 20px;
    margin-left: 10px;
    color: white;
    background-color: #d52323;
  }

  .anniu2 {
    font-size: 26px;
    margin-bottom: 20px;
    background-color: #015571;
    color: white;
  }

  .statusInfo {
    font-size: 26px;
    margin-bottom: 20px;
    color: #000000;
    span {
      font-size: 20px;
    }
  }

}

.dialogImg {
  width: 100px;
  margin-bottom: 15px;
}

.failTxt {
  text-align: center;
  font-size: 20px;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.baoming {
  margin: 0 auto;
  width: 950px;

  .title {
    font-size: 16px;
    border-left: 6px solid $base-border-color;
    padding-left: 10px;
    margin-bottom: 15px;
  }

  .resumeItem {
    position: relative;

    .icon_rudce {
      width: 20px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .addResume {
    text-align: center;
    margin-bottom: 22px;
    cursor: pointer;

    img {
      width: 20px;
      margin-right: 6px;
    }
  }

  .submit {
    padding-bottom: 20px;
  }
}

/deep/ .avatar-uploader {
  .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: $base-border-color;
    }
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 150px;
    height: 206px;
    display: block;
  }

  .sfz {
    width: 300px;
    height: 180px;
    display: block;
  }
}

.commitment {
  width: 1200px;
  margin: 0 auto;

  &-title {
    text-align: center;
    font-size: 30px;
    line-height: 60px;
  }
}
</style>