/* 系部建设 */
import req from '../api.js'
/* 交通与汽车产业系列表 */
export const HTTP_departmentCar = (page, size) => req('/api/department/car', { page, size })
/* 交通与汽车产业系详情 */
export const HTTP_departmentCarInfo = (id) => req('/api/department/carinfo', { id })

/* 数字信息产业系列表 */
export const HTTP_information = (page, size) => req('/api/department/information', { page, size })
/* 数字信息产业详情 */
export const HTTP_informationinfo = (id) => req('/api/department/informationinfo', { id })

/* 旅游与商贸产业系 */
export const HTTP_tourIndex = (page, size) => req('/api/department/tour', { page, size })
/* 旅游与商贸产业系详情 */
export const HTTP_tourinfo = (id) => req('/api/department/tourinfo', { id })

/* 智能技术产业 */
export const HTTP_departmentIndex = (page, size) => req('/api/department/index', { page, size })
/* 智能技术产业系详情 */
export const HTTP_departmentinfo = (id) => req('/api/department/departmentinfo', { id })

/* 民族文化产业系 */
export const HTTP_departmentNational = (page, size) => req('/api/department/national', { page, size })
/* 民族文化产业系详情 */
export const HTTP_departmentNationalinfo = (id) => req('/api/department/nationalinfo', { id })

/* 环境与能源产业系 */
export const HTTP_departmentEnergy = (page, size) => req('/api/department/energy', { page, size })
/* 环境与能源产业系详情 */
export const HTTP_departmentEnergyinfo = (id) => req('/api/department/energyinfo', { id })

/* 基础文化部 */
export const HTTP_departmentCultural = (page, size) => req('/api/department/cultural', { page, size })
/* 基础文化部详情 */
export const HTTP_departmentCulturalinfo = (id) => req('/api/department/culturalinfo', { id })

/* 专业建设 */
export const HTTP_profession = (cate, page, size) => req('/api/teach/profession', { cate, page, size })
/* 专业建设详情 */
export const HTTP_professioninfo = (id) => req('/api/teach/professioninfo', { id })

/* 教师风采 */
export const HTTP_mien = (cate, page, size) => req('/api/teach/mien', { cate, page, size })
/* 教师风采详情 */
export const HTTP_mieninfo = (id) => req('/api/teach/mieninfo', { id })

/* 师生活动 */
export const HTTP_sshd = (cate, page, size) => req('/api/news/sshd', { cate, page, size })
/* 师生活动详情 */
export const HTTP_sshddetail = (id) => req('/api/news/sshddetail', { id })

/* 院部动态 */
export const HTTP_ybdt = (page, size) => req('/api/news/ybdt', {page, size })
/* 院部动态详情 */
export const HTTP_ybdtdetail = (id) => req('/api/news/ybdtdetail', { id })