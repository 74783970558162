import req from '../api.js'
import { Session } from '@/utils/storage'
/* 首页信息 */
export const HTTP_Home = () => req('/api/index/home')
/* 公共信息 */
export const HTTP_Index = () => req('/api/index/index')
/* 用户详情 */
export const HTTP_UserInfo = (api_token = Session.get('js_api_token')) => req('/api/users/info', { api_token })
/* 新闻列表 */
export const HTTP_NewsIndex = (cate_id,page, size) => req('/api/news/index', { cate_id, page, size })
/* 时政要闻 */
export const HTTP_NewsSzyw = (page, size) => req('/api/news/szyw', { page, size })
/* 时政要闻详情 */
export const HTTP_NewsSzywDetail = (id) => req('/api/news/szywdetail', { id })
/* 新闻详情 */
export const HTTP_NewsDetail = (id) => req('/api/news/detail', { id })
/* 院部动态 */
export const HTTP_NewsYbdt = (page, size) => req('/api/news/ybdt', { page, size })
/* 院部动态 */
export const HTTP_NewsYbdtDetail = (id) => req('/api/news/ybdtdetail', { id })

/*菜单对应图片 */
export const HTTP_Menuimg = (mid) => req('/api/index/menuimg', { mid })
/* 搜索 */
export const HTTP_Search = (keyword, page, size) => req('/api/search/index', { keyword, page, size })