<template>
  <div class="box">
    <div class="box-item">
      <div class="box1">
        <img src="https://xzjsxy.net/img/1.jpg" alt />
      </div>
      <div class="box3">
        <div class="title flex-between">
          <div class="item">
            <img src="~images/icon13.png" alt srcset />
            教师风采
          </div>
          <img @click="more('jsfcList')" src="~images/icon15.png" alt />
        </div>
        <ul class="zyjs">
          <li v-for="a in jsfc" :key="a.id" @click="goRoute(a,'jsfcDetail')" class="flex-between">
            <p class="p1 flex-start">
              <img src="~images/icon17.png" alt />
              {{a.title}}
            </p>
            <p class="p2">{{a.date}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="box-item">
      <div class="box2">
        <div class="title flex-between">
          <div class="item">
            <img src="~images/icon11.png" alt srcset />
            系部动态
          </div>
          <img @click="moreYbdt" src="~images/icon15.png" alt />
        </div>
        <ul class="zyjs">
          <li v-for="a in ybdt" :key="a.id" @click="goXbdtDetail(a)" class="flex-between">
            <p class="p1 flex-start">
              <img src="~images/icon17.png" alt />
              {{a.title}}
            </p>
            <p class="p2">{{a.date}}</p>
          </li>
        </ul>
      </div>
      <div class="box5">
        <div class="title flex-between">
          <div class="item">
            <img src="~images/icon14.png" alt srcset />
            师生活动
          </div>
          <img @click="more('sshdList')" src="~images/icon15.png" alt />
        </div>
        <ul class="zyjs">
          <li v-for="a in sshd" :key="a.id" @click="goRoute(a,'sshdDetail')" class="flex-between">
            <p class="p1 flex-start" style="width: 70%">
              <img src="~images/icon17.png" alt />
              {{a.title}}
            </p>
            <p class="p2">{{a.date}}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="box-item">
      <div class="box4">
        <div class="title flex-between">
          <div class="item">
            <img src="~images/icon12.png" alt srcset />
            专业建设
          </div>
          <img @click="more('zyjsList')" src="~images/icon15.png" alt />
        </div>
        <ul class="zyjs">
          <li v-for="a in zyjs" :key="a.id" @click="goRoute(a,'zyjsDetail')" class="flex-between">
            <p class="p1 flex-start">
              <img src="~images/icon17.png" alt />
              {{a.title}}
            </p>
            <p class="p2">{{a.date}}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zyjs: [],
      jsfc: [],
      sshd: [],
      ybdt: [],
      cate: "national",
    };
  },
  methods: {
    getZyjs() {
      this.$api.HTTP_profession(this.cate, 0, 10).then((res) => {
        console.log(res);
        this.zyjs = res.list;
      });
    },
    getJsfc() {
      this.$api.HTTP_mien(this.cate, 0, 10).then((res) => {
        console.log(res);
        this.jsfc = res.list;
      });
    },
    getSshd() {
      this.$api.HTTP_sshd(this.cate, 0, 10).then((res) => {
        console.log(res);
        this.sshd = res.data;
      });
    },
    getYbdt() {
      this.$api.HTTP_departmentNational(0, 10).then((res) => {
        this.ybdt = res.list;
      });
    },
    more(name) {
      this.$router.push({
        name: name,
        query: {
          cate: this.cate,
        },
      });
    },
    moreYbdt() {
      this.$router.push({
        name: "mzwh",
      });
    },
    goRoute(a, name) {
      if (a.url) {
        window.open(a.url);
      } else {
        this.$router.push({
          name: name,
          query: {
            id: a.id,
          },
        });
      }
    },
    goXbdtDetail(a) {
      if (a.url) {
        window.open(a.url);
      } else {
        this.$router.push({
          name: "mzwhDetail",
          query: {
            id: a.id,
          },
        });
      }
    },
  },
  mounted() {
    this.getZyjs();
    this.getJsfc();
    this.getSshd();
    this.getYbdt();
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 1200px;
  &-item {
    display: flex;
  }
}
.box3 {
  width: 440px;
  padding-left: 20px;
}
.box2 {
  width: 734px;
}
.box4 {
  width: 734px;
}
.box5 {
  width: 440px;
  padding-left: 15px;
}
.box1 {
  margin-bottom: 10px;
  img {
    width: 734px;
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 4px solid #015571;
  height: 50px;
  .item {
    display: flex;
    align-items: center;
  }
  img {
    width: 30px;
  }
}
.zyjs {
  padding: 10px;
  li {
    margin-bottom: 10px;
    &:hover {
      color: #015571;
    }
  }
}
.p1 {
  img {
    width: 20px;
    margin-right: 6px;
  }
}
</style>
