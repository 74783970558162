<template>
  <div class="base-detail">
    <div class="container">
      <div class="title">{{ title }}</div>
      <div class="date">{{ date }}</div>
      <!-- <img :src="img" alt /> -->
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDetail",
  props: {
    //标题
    title: {
      type: String,
      default: "",
    },
    //日期
    date: {
      type: String,
      default: "",
    },
    //内容（富文本）
    content: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    //是否有上下篇部分
    haveOther: {
      type: Boolean,
      default: false,
    },
    //上一篇标题
    pre: {
      type: String,
      default: "无",
    },
    // 下一篇标题
    next: {
      type: String,
      default: "无",
    },
  },
  methods: {
    goUp() {
      this.$emit("goUp");
    },
    goDown() {
      this.$emit("goDown");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-detail {
  width: 1200px;
  margin: 0 auto 20px;
  background-color: #ffffff;
  /deep/ img{
    display: inline-block;
  }
  .container {
    padding: 35px 18px 0;
    text-align: center;
    .title {
      font-size: 26px;
      font-weight: 400;
      color: #474747;
    }

    .date {
      margin-top: 23px;
      margin-bottom: 33px;
      font-size: 14px;
      font-weight: 300;
      color: #707070;
    }

    .content {
      font-size: 16px;
      font-weight: 300;
      color: #474747;
      line-height: 24px;
      text-align: justify;
      text-indent: 2rem;
      padding-bottom: 30px;
    }
  }

  .btn-box {
    height: 60px;
    line-height: 60px;
    display: flex;
    justify-content: space-between;

    .btn {
      width: 620px;
      height: 60px;
      padding: 0 48px;
      border-top: 1px solid #f1f1f1;
      font-size: 16px;
      font-weight: 300;
      color: #707070;
      cursor: pointer;

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.none {
        cursor: default;
        pointer-events: none;
      }
    }

    .icon {
      width: 58px;
      height: 60px;
      border: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
</style>
