import req from '../api.js'
/* 专业建设列表 */
export const HTTP_teachProfession = (page, size) => req('/api/teach/profession', { page, size })
/* 专业建设详情 */
export const HTTP_teachProfessionInfo = (id) => req('/api/teach/professioninfo', { id })

/* 教学成果列表 */
export const HTTP_teachAchievement = (page, size) => req('/api/teach/achievement', { page, size })
/* 教学成果详情 */
export const HTTP_teachAchievementInfo = (id) => req('/api/teach/achievementinfo', { id })

/* 教学研究列表 */
export const HTTP_teachIndex = (page, size) => req('/api/teach/index', { page, size })
/* 教学研究详情 */
export const HTTP_teachDetail = (id) => req('/api/teach/detail', { id })

/* 教师风采列表 */
export const HTTP_teachMine = (page, size) => req('/api/teach/mien', { page, size })
/* 教师风采详情 */
export const HTTP_teachMineInfo = (id) => req('/api/teach/mieninfo', { id })