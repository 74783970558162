<template>
  <div class="navbar flex-center">
    <img class="home" src="~images/icon1.png" alt />
    <ul class="nav">
      <template v-for="(a, i) in navMenu">
        <router-link v-if="!a.meta.isBlankLink" tag="li" :to="{ name: a.name }" :key="i">
          <div class="fanav">{{ a.meta.title }}</div>
          <!-- <div v-if="a.children&&a.children.length" class="bar"></div> -->
          <div v-if="a.children && a.children.length" class="smallNav">
            <template v-for="(b, j) in a.children">
              <router-link v-if="b.meta.nav && !b.meta.isBlankLink" class="subTitle" tag="div"
                :active-class="'subTitleActive'" :to="{ name: b.name }" :key="j">{{ b.meta.title }}</router-link>
              <a v-if="b.meta.nav && b.meta.isBlankLink" class="ablank fanav" :href="b.meta.isBlankLink" target="_blank"
                rel="noopener noreferrer">
                {{ b.meta.title }}
              </a>
            </template>
          </div>
        </router-link>
        <router-link v-if="a.meta.isBlankLink" tag="li" to="/cjlm">
          <a v-if="a.meta.isBlankLink" class="ablank fanav" :href="a.meta.isBlankLink" target="_blank"
             rel="noopener noreferrer">{{ a.meta.title }}
          </a>
          <div v-if="a.children && a.children.length" class="smallNav">
            <template v-for="(b, j) in a.children">
              <router-link v-if="b.meta.nav && !b.meta.isBlankLink" class="subTitle" tag="div"
                           :active-class="'subTitleActive'" :to="{ name: b.name }" :key="j">{{ b.meta.title }}</router-link>
              <a v-if="b.meta.nav && b.meta.isBlankLink" class="ablank fanav" :href="b.meta.isBlankLink" target="_blank"
                 rel="noopener noreferrer">
                {{ b.meta.title }}
              </a>
            </template>
          </div>
        </router-link>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navMenu: [],
    };
  },
  mounted() {
    this.navMenu = this.$router.options.routes.filter((o) => o.meta.nav);
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;

  .home {
    margin-right: 20px;
  }
}

.ablank {
  position: relative;
  padding: 0 20px;
  white-space: nowrap;
  color: #000000;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  display: inline-block;

  &:hover {
    color: #f4a400;
  }
}

.nav {
  display: flex;
  justify-content: flex-start;

  li {
    position: relative;
    padding: 0 20px;
    cursor: pointer;

    .fanav {
      white-space: nowrap;
      color: #000000;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      display: inline-block;

      &:hover {
        color: #f4a400;
      }
    }

    &.router-link-active {
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -4px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid #015571;
      }
    }

    &:hover {
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -4px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid #015571;
      }

      .smallNav {
        display: block;
      }
    }

    .smallNav {
      z-index: 10;
      display: none;
      position: absolute;
      top: 50px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 18px;
      color: #fff;
      background: #015571;

      .subTitle {
        white-space: nowrap;
        height: 60px;
        line-height: 60px;
        padding: 0 10px;
        text-align: center;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
          color: #f5a911ef;
        }
      }

      .subTitleActive {
        opacity: 1;
        color: #f4a400;
      }
    }
  }
}
</style>
