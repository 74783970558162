import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import "@/element-ui";
import "@/style/base.scss";
import "@/assets/icon/index.css";
import '@/style/element-variables.scss';
// 改变主题色变量
import { isDebugMode } from "@/config";
Vue.config.productionTip = false;
import VueWechatTitle from "vue-wechat-title";
Vue.use(VueWechatTitle);
import api from "@/http";
Vue.use(api);
import ErrorPlugin from "@/config/error";
Vue.use(ErrorPlugin);
Vue.config.productionTip = isDebugMode;
/* 注册全局组件 */
import '@/components/globalComponents.js'
new Vue({
  router,
  // store,
  render: (h) => h(App),
}).$mount("#app");
