const _import = require('../config/import')
const sygzRoute = [
  {
    path: '/sygz',
    component: _import('modules/sygz/index'),
    name: 'sygz',
    meta: { title: '受援工作',nav:true,isBreadcrumb:true},
    children:[]
  },
  {
    path: '/sygzDetail',
    component: _import('modules/sygz/detail'),
    name: 'sygzDetail',
    meta: { title: '受援工作',nav:false,isBreadcrumb:true},
  },
]
export default sygzRoute