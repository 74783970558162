import req from '../api.js'
import { Session } from '@/utils/storage'
/* 招生简章列表 */
export const HTTP_signZsjh = (page, size) => req('/api/sign/zsjh', { page, size })
/* 招生简章详情 */
export const HTTP_signZsjhinfo = (id) => req('/api/sign/zsjhinfo', { id })


/* 学生实习 */
export const HTTP_signXssx = (page, size) => req('/api/sign/xssx', { page, size })
/* 学生实习详情 */
export const HTTP_signXssxInfo = (id) => req('/api/sign/xssxinfo', { id })

/* 报考咨询列表 */
export const HTTP_signBkzx = (page, size) => req('/api/sign/bkzx', { page, size })
/* 报考咨询详情 */
export const HTTP_signBkzxinfo = (id) => req('/api/sign/bkzxinfo', { id })

/* 网上报名首页 */
export const HTTP_signWsbm = (api_token = Session.get('js_api_token')) => req('/api/sign/wsbm', { api_token })
/* 报名信息 */
export const HTTP_signIndex = (cate_id,api_token = Session.get('js_api_token')) => req('/api/sign/index', { cate_id,api_token })
/* 报名提交 */
export const HTTP_signSave = (data, api_token = Session.get('js_api_token')) => req('/api/sign/save', { ...data, api_token },{method:'post'})

/* 就业创业列表 */
export const HTTP_signJycy = (page, size) => req('/api/sign/jycy', { page, size })
/* 就业创业详情 */
export const HTTP_signJycyinfo = (id) => req('/api/sign/jycyinfo', { id })

/* 联系方式 */
export const HTTP_signContact = () => req('/api/sign/contact')

/* 毕业专业列表 */
export const HTTP_signProfession = (api_token = Session.get('js_api_token')) => req('/api/sign/professional', { api_token })

/* 志愿专业列表 */
export const HTTP_signMajor = (api_token = Session.get('js_api_token')) => req('/api/sign/major', { api_token })

/* 学校列表 */
export const HTTP_signSchool = (api_token = Session.get('js_api_token')) => req('/api/sign/school', { api_token })

/* 承诺书 */
export const HTTP_signCommitment = () => req('/api/sign/commitment')

/* 上传文件 */
export const HTTP_Uploads = (formData) => req('/api/users/uploads', formData, { useUpload: true })