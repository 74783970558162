const ls = window.localStorage
const ss = window.sessionStorage

export const Cookie = {
  set(name, value, expiredays) {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
  },
  get(name) {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
      return (arr[2]);
    else
      return null;
  },
  remove(name) {
    document.cookie = name + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的
    document.cookie = name + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的
  },
  //清空cookie
  clearCookie() {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
      for (var i = keys.length; i--;) {
        document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的
        document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的
      }
    }
    console.log('已清除');
  }

}
export const Local = {
  // 判断浏览器是否支持LocalStorage
  hasLocalStorage: function () {
    return window.Storage && window.localStorage && window.localStorage instanceof Storage
  },
  get(key) {
    if (this.hasLocalStorage()) {
      if (key) return JSON.parse(ls.getItem(key))
      return null
    } else {
      return Cookies.get(key)
    }
  },
  set(key, val, day) {
    if (this.hasLocalStorage()) {
      const setting = arguments[0]
      if (Object.prototype.toString.call(setting).slice(8, -1) === 'Object') {
        for (const i in setting) {
          ls.setItem(i, JSON.stringify(setting[i]))
        }
      } else {
        ls.setItem(key, JSON.stringify(val))
      }
    } else {
      Cookies.set(key, val, day)
    }
  },
  remove(key) {
    if (this.hasLocalStorage()) {
      ls.removeItem(key)
    } else {
      Cookies.remove(key)
    }
  },
  clear() {
    ls.clear()
  }
}

export const Session = {
  get(key) {
    if (key) return JSON.parse(ss.getItem(key))
    return null
  },
  set(key, val) {
    const setting = arguments[0]
    if (Object.prototype.toString.call(setting).slice(8, -1) === 'Object') {
      for (const i in setting) {
        ss.setItem(i, JSON.stringify(setting[i]))
      }
    } else {
      ss.setItem(key, JSON.stringify(val))
    }
  },
  remove(key) {
    ss.removeItem(key)
  },
  clear() {
    ss.clear()
  }
}
