<!-- 校企合作 -->

asdasd
<script>
export default {
  data() {
    return {
      list: [],
      page: 0,
      size: 10,
      total: 0,
    };
  },
  mounted() {
    window.open("SDs", '_blank');
  },
  methods: {
    goRoute(a) {
      if (a.url) {
        window.open(a.url);
      } else {
        this.$router.push({
          name: "xqhzDetail",
          query: { id: a.id },
        });
      }
    },
    getList() {
      let { page, size } = this;
      this.$api.HTTP_factorsIndex(page, size).then((res) => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    getPageNum(e) {
      this.page = e-1;
      this.getList();
    },
  },
};
</script>

>
