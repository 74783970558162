<template>
  <section class="app-main">
    <transition>
      <keep-alive include="search">
        <router-view class="main" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
};
</script>

<style lang="scss" scoped>
.app-main {
  width: 100%;
  position: relative;
  height: 100%;
  overflow-y: auto;
  .main {
    // width: 1200px;
    margin: 0 auto;
    padding-top: 250px;
  }
}
</style>
