const _import = require('../config/import')
const xqhzRoute = [
  {
    path: '/xqhz',
    component: _import('modules/xqhz/index'),
    name: 'xqhz',
    meta: { title: '产教联盟', nav: true,isBlankLink:"https://cjlm.xzjsxy.net/"},
    redirect:{name:'cjlm'},
    children: [
      {
          path: 'xqhz',
          component: _import('modules/xqhz/index'),
          name: 'xqhz',
          meta: { title: '校企合作', nav: true, isBreadcrumb: true },
      },
      {
        path: 'cjlm',
        component: _import('modules/xqhz/cjlm'),
        name: 'cjlm',
        meta: { title: '产教联盟', nav: true, isBreadcrumb: true,isBlankLink:"https://cjlm.xzjsxy.net/"},
      },
    ]
  },
  {
    path: '/xqhzDetail',
    component: _import('modules/xqhz/detail'),
    name: 'xqhzDetail',
    meta: { title: '校企合作', nav: false, isBreadcrumb: true },
    children: []
  }






]
export default xqhzRoute