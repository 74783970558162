/**
 * @Description [http请求封装无加密版本]
 */
const IS_PROD = ['production', 'prod'].includes(process.env.NODE_ENV)
import axios from 'axios'
import qs from "qs" // 序列化请求数据
import { Message } from 'element-ui'
import { deepMerge } from '@/utils/common'
import { baseURL } from '@/config'
let cancel; const promiseArr = {}
const CancelToken = axios.CancelToken
// 配置全局取消数组
window.__axiosPromiseArr = []

const instance = axios.create({
  baseURL: IS_PROD ? baseURL : '',
  headers: {
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    // 'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json'
  },
  timeout: 60000
})

// 常见http错误码列表
const HTTP_ERR_CODE_LIST = {
  [400]: "请求错误",
  [401]: "未授权，请登录",
  [403]: "拒绝访问",
  [404]: "请求地址出错",
  [408]: "请求超时",
  [500]: "服务器内部错误",
  [501]: "服务未实现",
  [502]: "网关错误",
  [503]: "服务不可用",
  [504]: "网关超时",
  [505]: "HTTP版本不受支持"
}
//业务接口错误码列表
const API_ERR_CODE_LIST = {
  [404]: "接口未定义",
  [405]: "登录异常，请先登录！",
}
instance.interceptors.request.use(config => {
  if (config.cancelRepeat) {
    // 取消同时发起的多次请求
    if (promiseArr[config.url]) {
      // 二次
      promiseArr[config.url]('取消相同接口请求')
      promiseArr[config.url] = cancel
    } else {
      // 首次
      promiseArr[config.url] = cancel
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(response => {
  let res = response.data;
  let code = res.status;
  if (API_ERR_CODE_LIST[code]) {
    const errMsg = API_ERR_CODE_LIST[code];
    Message({
      message: errMsg,
      type: 'error'
    })
    return Promise.reject(errMsg);
  }
  if (response.config.customResponse) {
    return Promise.resolve(res)
    /* 1, 9001, 9002 都是请求正确的状态码 跟后端商定好 */
  } else if ([1, 9001, 9002].includes(code)) {
    return Promise.resolve(res.data);
  } else {
    Message({
      message: res.msg,
      type: 'error',
    })
    return Promise.reject(res.msg);
  }
}, error => {
  if (error && error.response) {
    const errCode = +error.response.status //错误码
    const errMsg = HTTP_ERR_CODE_LIST[errCode] //错误消息
    error.message = errMsg
    Message({
      message: error.message,
      type: 'error'
    })
  }
  return Promise.reject(error) // 返回接口返回的错误信息
})

/**
 * @param    {String}   url                 [请求地址]
 * @param    {Object}   data                [请求数据]
 * @param    {Object}   opt                 [请求配置信息] 自定义添加配置项
 * @param    {Boolean}  opt.customResponse  [自定义处理返回值，默认统一处理]
 * @param    {Boolean}  opt.cancelRepeat    [是否取消同时执行的多次请求，取消多次true、允许多次false]
 * @return   {[type]}                       [http实例]
 */

const REQUEST_API = (url = '', data = {}, opt = {}) => {
  const DEFAULT_CONFIG_OPTIONS = {
    method: 'GET',
    customResponse: false,
    cancelRepeat: true
  }

  const options = deepMerge(DEFAULT_CONFIG_OPTIONS, opt)

  const datas = { p: 'w', ...data }

  return new Promise((resolve, reject) => {
    if (options.useUpload) {
      const method = options.method && options.method.toLowerCase() || 'POST'

      /**
       * @description  文件上传
       */
      const EXTRA_CONFIG = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }

      const config = deepMerge(EXTRA_CONFIG, options)

      const formdata = new FormData()
      Object.keys(datas).forEach(key => {
        if (Array.isArray(datas[key]) && datas[key].length) {
          datas[key].forEach(item => {
            formdata.append(`${key}[]`, item)
          })
        } else {
          formdata.append(key, datas[key])
        }
      })

      instance[method](url, formdata, config)
        .then(res => {
          resolve(res)
          return false
        })
        .catch(err => {
          reject(err)
        })
    } else {
      /**
       * @description  常用http请求
       */
      options.method = options.method && options.method.toUpperCase() || 'GET'

      const config = {
        url,
        ...(
          options.method === 'GET' ? { params: datas }
            : (options.method === 'POST' ||
              options.method === 'PUT' ||
              options.method === 'DELETE') ? { data: qs.stringify(datas) }
              : null
        ),
        ...options,
        cancelToken: new CancelToken(c => {
          cancel = c
          window.__axiosPromiseArr.push({ cancel })
        })
      }
      instance(config)
        .then(res => {
          resolve(res)
          return false
        })
        .catch(err => {
          reject(err)
        })
    }
  })
}

export default REQUEST_API
