/**
 * [全局配置]
 */

/**
 * @导出配置
 */
module.exports = {
  /**
   * @description 模式判断 development production
   */
  isDebugMode: process.env.NODE_ENV == 'development',
  /**
   * devToken
   */
  devToken: process.env.NODE_ENV == 'development' ? '123':'c2456c8253f6b5699cbcfa14fc41b3a7',
  /**
   * @type {boolean} true | false
   * @description admin title
   */
  titlePrefix: '西藏技师学院-',
  /**
   * @type {object}
   * @description 屏幕分辨率
   */
  screenResolution: {
    width: 1920,
    height: 1080
  },
  /**
  * @type {string}  history
  * @description route-mode
  */
  routeMode: 'history',
  /**
  * @type {string}
  * @description route-base
  */
  routeBase: '/',
  /**
  * @type {string}
  * @description 接口请求地址，图片文件地址
  */
  // baseURL: 'http://xzjsxy.com'
  baseURL: 'https://xzjsxy.net'


}
