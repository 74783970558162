const _import = require('../config/import')
import ddjsRoute from './ddjs'
import homeRoute from './home'
import jxjyRoute from './jxjy'
import xbjsRoute from './xbjs'
import xygkRoute from './xygk'
import xsglRoute from './xsgl'
import twgzRoute from './twgz'
import zsjyRoute from './zsjy'
import pxjdRoute from './pxjd'
import sygzRoute from './sygz'
import xqhzRoute from './xqhz'
const constantRoutes = [
  {
    path: '/',
    // component: _import('layout/index'),
    // name: 'layout',
    meta: { title: '西藏技师学院',nav:false },
    redirect: '/home',
    // children: modulesRoute
  },
  ...homeRoute,
  ...xygkRoute,
  ...ddjsRoute,
  ...xbjsRoute,
  ...jxjyRoute,
  ...xsglRoute,
  ...twgzRoute,
  ...zsjyRoute,
  ...pxjdRoute,
  ...sygzRoute,
  ...xqhzRoute,
  {
    path: '/search',
    component: _import('modules/home/search'),
    name: 'search',
    meta: { title: '搜索', nav: false },
  },
  {
    path: '*',
    component: _import('error/404'),
    name: '404',
    hidden: true, meta: { title: '404未找到' }
  }
]

export default constantRoutes
