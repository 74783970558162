<!-- banner -->
<template>
  <div class="banner">
    <img class="banner-img" :src="img" alt/>
<!--    <p class="banner-title">{{ title }}</p>-->
    <div class="banner-breadcrumb">
      <div class="myLoaction">
        <img src="@/assets/images/weizhi.png" alt/> 您的位置：
      </div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          v-for="a in breadcrumb_list"
          :key="a.name"
        >{{a.meta.title}}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="myLoaction">
        <a style="color: #fff" onclick="window.history.go(-1);">返回上一页</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
  },
  data() {
    return {
      img: {},
      title:''
    }
  },
  computed: {
    breadcrumb_list: function () {
      let matchedRoute = this.$route.matched;
      this.title = this.$route.meta.title;
      return matchedRoute.filter(o=>o.meta.isBreadcrumb);
    },
  },
  mounted() {
    this.$api.HTTP_Menuimg(this.id).then((res) => {
      this.img = res
    })
  },
}
</script>
<style lang='scss' scoped>
.banner {
  position: relative;

  &-img {
    width: 100%;
    height: 600px;
    display: block;
  }

  &-title {
    // width: 300px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    position: absolute;
    bottom: 63px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    padding-bottom: 10px;

    &::after {
      content: "";
      display: block;
      width: 85%;
      height: 3px;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &::before {
      content: "";
      display: block;
      width: 10%;
      height: 3px;
      background: #fff;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &-breadcrumb {
    display: flex;
    align-items: center;
    width: 100%;
    height: 34px;
    background: #9e6c24;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;

    /deep/ .el-breadcrumb__inner {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }

    .myLoaction {
      height: 50px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      img {
        margin-right: 6px;
        width: 20px;
      }
    }
  }
}
</style>
