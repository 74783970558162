import req from '../api.js'
/* 先锋旗帜列表 */
export const HTTP_partyIndex = (page, size) => req('/api/party/index', { page, size })
/* 先锋旗帜详情 */
export const HTTP_partyDetail = (id) => req('/api/party/partyinfo', { id })

/* 党建活动列表 */
export const HTTP_activeIndex = (page, size) => req('/api/party/active', { page, size })
/* 党建活动详情 */
export const HTTP_activeInfo = (id) => req('/api/party/activeinfo', { id })

/* 党风廉政列表 */
export const HTTP_dflzIndex = (page, size) => req('/api/party/dflz', { page, size })
/* 党风廉政详情 */
export const HTTP_dflzDetail = (id) => req('/api/party/dflzinfo', { id })